export default {
  "AccountOverview": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZAHLUNGSKONTO"])},
    "available_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davon Verfügbar"])},
    "current_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Kontostand"])},
    "past_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERGANGENE TRANSAKTIONEN"])},
    "pay_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlen"])},
    "pay_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlen"])},
    "pending_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUSSTEHENDE TRANSAKTIONEN"])}
  },
  "AccountPlaceholder": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden zukünftig die persönlichen Transaktionen angezeigt."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Informationen"])}
  },
  "AccountView": {
    "invested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investiert"])},
    "new_assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Investitionsmöglichkeiten entdecken"])},
    "open_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Angebote"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])}
  },
  "AddPayoutAccountModal": {
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bic_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC in Zwischenablage kopiert"])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich beim Support!"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönlicher IBAN"])},
    "iban_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN in Zwischenablage kopiert"])},
    "info_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verwendungszweck kann frei gewählt werden, da dies ein persönliches Zahlungskonto ist."])},
    "info_2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen kommen normalerweise innerhalb von zwei Banktagen an."])},
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte folgendes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ein Auszahlungskonto hinzuzufügen, muss mindestens 1 Cent vom persönlichen Bankkonto auf das Zahlungskonto überweisen werden. So können wir sicher stellen, dass es sich um ein persönliches Auszahlungskonto handelt. Der überwiesene Betrag kann entweder investiert oder wieder ausbezahlt werden."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto hinzufügen"])}
  },
  "AddPayout": {
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto hinzufügen"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungskonto hinzufügen"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später erledigen"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor Auszahlungen durchführt werden können, muss ein Auszahlungskonto hinzugefügt werden."])}
  },
  "AreYouHappyWithTheAppModal": {
    "buttonNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "buttonYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt die App bewerten?"])}
  },
  "AssetCard": {
    "asset_invested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["investiert"])},
    "assetSlider_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEHR ENTDECKEN"])},
    "borrowedCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "borrowedCapital_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Finanzprodukt hat einen integrierten Fremdkapitalanteil. Der/die Investor:in investiert dadurch in ein gehebeltes Produkt. Details zur Fremdfinanzierung sind unter “Investment Infos” zu finden."])},
    "builderOwnerModel_constructionStart_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplanter Baubeginn:"])},
    "builderOwnerModel_durationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlene Laufzeit:"])},
    "builderOwnerModel_durationValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["langfristig"])},
    "constructionStatusProject_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt wurde noch nicht fertiggestellt und befindet sich aktuell in Bau."])},
    "constructionStatusStock_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei diesem Objekt handelt sich um ein Bestandsobjekt."])},
    "duration_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit:"])},
    "endless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endlos"])},
    "expectedBuildingStartDate_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Baubeginn ist für das angegebene Datum vorgesehen, kann sich jedoch verzögern."])},
    "expectedBuildingStartDate_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtlicher Baubeginn "])},
    "expected_rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen erw. ab:"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab "])},
    "kest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KESt"])},
    "kest_modalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen unterliegen der KESt, welche durch die Emittentin abgeführt wird."])},
    "minAmountPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestinvestition"])},
    "minAmountPrice_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für dieses Asset gilt ein Mindestzeichnungsvolumen. Investitionen können ab dem angegebenen Betrag am Primärmarkt getätigt werden."])},
    "minAmountPrice_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestzeichnungsvolumen"])},
    "net_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neu"])},
    "normalScenario_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittleres Szenario"])},
    "partnerOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Angebot der"])},
    "perAnnual_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.a."])},
    "plannedSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gepl. Verkauf in"])},
    "pricePerShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "pricePerShare_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile können ab dem angegeben Preis erworben werden."])},
    "pricePerShare_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "primary_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzierungsphase"])},
    "rentalDate_modalDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier wird angegeben, ab wann  mit regelmäßigen Ausschüttungen gerechnet wird  bzw. wann voraussichtlich die nächste Ausschüttung erwartet wird."])},
    "rentalDate_modalHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen"])},
    "rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. nächste Ausschüttung:"])},
    "secondary_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel aktiv"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])},
    "yieldPotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])}
  },
  "AssetChart": {
    "last_price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Preis / Anteil"])},
    "lastTransactionModal_firstParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Preis / Anteil:"])},
    "lastTransactionModal_firstParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierbei handelt es sich um den letzten Preis zu dem ein Anteil am Marktplatz gehandelt wurde."])},
    "lastTransactionModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklärungen zum Chart"])},
    "lastTransactionModal_secondParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung:"])},
    "lastTransactionModal_secondParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Klick auf einen Punkt im Chart zeigen wir den Marktwert an. Dieser berechnet sich auf Basis der insgesamt verfügbaren Anteile multipliziert mit dem jeweiligen gleitenden Durchschnittspreis zu diesem Zeitpunkt."])},
    "lastTransactionModal_thirdParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleitender Durchschnitt:"])},
    "lastTransactionModal_thirdParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Chart zeigen wir den gewichteten Durchschnittspreis der jeweils letzten 30 Tage."])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "moving_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleitender Durchschnitt"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis / Anteil"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])}
  },
  "AssetDetails": {
    "document_plantList_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagenliste"])},
    "tab_myInvestment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Investment"])},
    "tab_powerPlantInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwerk"])},
    "tab_realEstateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])}
  },
  "AssetFooter": {
    "documentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "legal_paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * Die erwartete Ausschüttung berechnet sich auf Basis des Netto-Mietüberschusses über die im Mietvertrag vereinbarte Nettomiete und kommt jeweils nur zur Auszahlung, wenn der/die Mieter:in diese bezahlt hat."])},
    "legal_paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Produkte mit einem Fremdkapitalanteil können sich aufgrund der Hebelwirkung (Leverage) überproportional sowohl positiv als auch negativ entwickeln. Wir weisen ausdrücklich darauf hin, dass vergangene Entwicklungen und Prognosen kein zuverlässiger Indikator für künftige Wertentwicklungen sind und es sich bei den Renditeangaben lediglich um kalkulierte Werte handelt. "])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "revenuePoolDocuments_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendite Pool"])}
  },
  "AssetHighlights": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhepunkte der Beteiligung"])}
  },
  "AssetInfoCard": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab"])},
    "rentalYield_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung"])},
    "returnOnEquity_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])}
  },
  "AssetInfo": {
    "document_expertProtocol_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachverständigenprotokoll"])},
    "document_landRegister_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuch"])},
    "document_utilityReport_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzwertgutachten"])},
    "energy_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENERGIEBEDARF"])},
    "floor_plans_and_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRUNDRISS"])},
    "object_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien Infos"])},
    "powerPlant_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwerk Infos"])},
    "realEstate_buildingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "realEstate_equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstattung"])},
    "realEstate_floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockwerk"])},
    "realEstate_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien Infos"])},
    "realEstate_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizung"])},
    "realEstate_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
    "realEstate_structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struktur"])},
    "realEstate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien Infos"])},
    "realEstate_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])}
  },
  "AssetInfoFooter": {
    "expert_protocol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachverständigenprotokoll"])},
    "land_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuch"])},
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOKUMENTE ZUM OBJEKT"])},
    "useful_value_appraisal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzwertgutachten"])}
  },
  "AssetMenu": {
    "asset_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien Infos"])},
    "asset_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
    "investment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Infos"])},
    "location_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Lage"])},
    "menu_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All details"])},
    "powerPlant_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwerk Infos"])},
    "powerplant_rentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Einnahmen"])},
    "records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates zum Objekt"])},
    "rent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietübersicht"])},
    "rentInfo_returnOnEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietübersicht und Kalkulation"])}
  },
  "AssetOverview": {
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistung"])},
    "constructionStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtlicher Baubeginn"])},
    "correct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "detailsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "durationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerstehend"])},
    "endless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endlos"])},
    "leverageOnDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil per"])},
    "leverageOnDayModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Finanzprodukt hat einen integrierten Fremdkapitalanteil. Der/die Investor:in investiert dadurch in ein gehebeltes Produkt. Details zur Fremdfinanzierung sind unter “Investment Infos” zu finden."])},
    "leverageOnDayModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapital"])},
    "leverageVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "leverageVolumeModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Finanzprodukt hat einen integrierten Fremdkapitalanteil. Der/die Investor:in investiert dadurch in ein gehebeltes Produkt. Details zur Fremdfinanzierung sind unter “Investment Infos” zu finden."])},
    "leverageVolumeModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "marketCapitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "marketCapitalizationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Marktkapitalisierung berechnet sich auf Basis der ingesamt verfügbaren Anteile multipliziert mit dem letzten Preis zu dem ein Anteil am Marktplatz gehandelt wurde. "])},
    "marketCapitalizationModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "market_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "mediumScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittleres Szenario"])},
    "minInvestment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestinvestition"])},
    "next_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Ausschüttung"])},
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich zum"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet ab"])},
    "notCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "overallSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtanlage"])},
    "payOutPotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungspotenzial"])},
    "payOutPotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungspotenzial"])},
    "perAnnual_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.a."])},
    "performancePotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertentwicklungspotenzial"])},
    "performancePotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertentwicklungspotenzial"])},
    "plannedSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gepl. Verkauf in"])},
    "promotionalLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förderdarlehen"])},
    "realPart_totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtinvestitionssumme"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung"])},
    "rentalStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtlicher Mietbeginn"])},
    "rentalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermietungsstatus"])},
    "repaymentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlungsbetrag"])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Produkte mit einem Fremdkapitalanteil können sich aufgrund der Hebelwirkung (Leverage) überproportional sowohl positiv als auch negativ entwickeln. Wir weisen ausdrücklich darauf hin, dass vergangene Entwicklungen und Prognosen kein zuverlässiger Indikator für künftige Wertentwicklungen sind und es sich bei den Renditeangaben lediglich um kalkulierte Werte handelt."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "share_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Anteile"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "totalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtnutzfläche"])},
    "total_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile Gesamt"])},
    "totalVolume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtvolumen"])},
    "totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtvolumen"])},
    "totalVolumeModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Marktkapitalisierung berechnet sich auf Basis der ingesamt verfügbaren Anteile multipliziert mit dem letzten Preis zu dem ein Anteil am Marktplatz gehandelt wurde. Der Fremdkapitalanteil gibt den offenen Darlehnsbetrag an."])},
    "totalVolumeModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtvolumen"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])},
    "until_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "withoutChart_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald alle Anteile der Emittentin verkauft sind, wird hier die historische Entwicklung angezeigt."])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])},
    "yieldPotential_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])}
  },
  "AssetRating": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Immobilie wurde  von einem unabhängigen Gutachter bewertet."])}
  },
  "AssetRentInfo": {
    "document_rentalAgreement_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietvertrag"])},
    "expected_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen erwartet ab"])},
    "liquidity_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquiditätsrücklage"])},
    "management_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managementkosten"])},
    "net_rental_income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-Mieteinnahmen"])},
    "net_rental_surplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-Mietüberschuss"])},
    "occupancy_rate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die nachfolgende Quote gibt Auskunft über den durchschnittlichen Zeitraum pro Jahr in dem die Immobilie vermietet war."])},
    "occupancy_rate_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERMIETUNGSGRAD"])},
    "powerplant_rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Einnahmen"])},
    "progress_bar_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Informationen lassen keine Rückschlüsse auf zukünftige Entwicklungen zu."])},
    "property_management_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausverwaltungskosten"])},
    "rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietübersicht"])},
    "rental_return_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Die erwartete Ausschüttung berechnet sich auf Basis des Netto-Mietüberschusses über die im Mietvertrag vereinbarte Nettomiete und kommt jeweils nur zur Auszahlung, wenn der/die Mieter:in diese bezahlt hat. "])},
    "rental_return_text_bambus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Die erwartete, laufende Ausschüttung  berechnet sich auf Basis des dem im Miteigentumsvertrag vereinbarten Nutzungsentgelt abzgl. des Anteils für den Emittenten und kommt nur zur Auszahlung wenn das Nutzungsentgelt vom Miteigentümer bezahlt wird."])},
    "rental_return_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung p.a."])}
  },
  "AssetSideContent": {
    "moreAssetsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Objekte"])},
    "myInvestmentsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Investment"])}
  },
  "AssetSlider": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEHR ENTDECKEN"])}
  },
  "AssetView": {
    "chart_lastTransaction_modal_firstParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Preis / Anteil:"])},
    "chart_lastTransaction_modal_firstParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hierbei handelt es sich um den letzten Preis zu dem ein Anteil am Marktplatz gehandelt wurde."])},
    "chart_lastTransaction_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erklärungen zum Chart"])},
    "chart_lastTransaction_modal_secondParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung:"])},
    "chart_lastTransaction_modal_secondParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Klick auf einen Punkt im Chart zeigen wir den Marktwert an. Dieser berechnet sich auf Basis der insgesamt verfügbaren Anteile multipliziert mit dem jeweiligen gleitenden Durchschnittspreis zu diesem Zeitpunkt."])},
    "chart_lastTransaction_modal_thirdParagraph_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleitender Durchschnitt:"])},
    "chart_lastTransaction_modal_thirdParagraph_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Chart zeigen wir den gewichteten Durchschnittspreis der jeweils letzten 30 Tage."])},
    "info_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
    "mobile_add_to_cart_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "mobile_add_to_cart_sell_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufen"])},
    "not_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Erstellen"])},
    "offer_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Investment"])},
    "popup_header_notlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle einen Account um zu investieren."])},
    "popup_header_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account ist noch nicht vollständig verifiziert!"])},
    "popup_text_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir informieren per E-Mail, sobald wir alle Informationen überprüft haben."])},
    "popup_text_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließe die Verifizierung ab, um zu investieren."])},
    "popup_underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe bereits einen Account"])},
    "powerPlant_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwerk"])},
    "purchase_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Anteile kaufen"])},
    "realEstate_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
    "review_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Immobilie wurde von einem unabhängigen Gutachter bewertet."])},
    "share_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe eine interessante Investitionsmöglichkeit gefunden!"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verifizieren"])},
    "withoutChart_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald alle Anteile der Emittentin verkauft sind, wird hier die historische Entwicklung angezeigt."])}
  },
  "BankAccountVerification": {
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt verifizieren"])},
    "info_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankdatenabgleich per Sofortüberweisung"])},
    "info_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind gesetzlich dazu verpflichtet die Identität unserer Kunden zu verifizieren."])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später fortfahren"])}
  },
  "Biometrics": {
    "button_face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face ID verwenden"])},
    "button_finger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch ID verwenden"])},
    "face": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face ID verwenden?"])},
    "finger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch ID verwenden?"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt nicht"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellere Anmeldung ohne Passwort Eingabe."])}
  },
  "BuyOfferOverview": {
    "buy_contact_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Kontaktaufnahme"])},
    "buy_contact_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden folgende Kontaktdaten angegeben, damit mit der/die Verkäufer:inn Kontakt aufnehmen kann bzgl. der Zahlungsabwicklung."])},
    "buy_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme der Anfrage durch Verkäufer:in"])},
    "buy_finish_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte auf die Freigabe der Verkäufer:in bis die gewünschten Anteile übertragen werden."])},
    "buy_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung durchführen"])},
    "buy_payment_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahle den Gesamtbetrag von"])},
    "buy_payment_body1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an die Verkäufer:in."])},
    "buy_payment_body_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlung ist eingelangt."])},
    "buy_payment_release_final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf abschließen"])},
    "buy_payment_release_final_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App, um den Kauf abzuschließen."])},
    "buy_payment_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlung erfolgreich"])},
    "buy_released": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungspflichtig kaufen"])},
    "buy_released_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App um das Kaufangebot zu signieren."])},
    "buy_released_body_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Kaufangebot wurde mit der Security App signiert."])},
    "buy_released_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf freigegeben"])},
    "buy_released_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf freigegeben"])},
    "buy_released_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufangebot signiert"])},
    "buy_release_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf freigeben"])},
    "buy_release_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald die Zahlung durchgeführt wurde, kannst du mit dem unten folgenden Button den Kauf der Anteile freigeben."])},
    "buy_seller_accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme der Anfrage von Investor:innen"])},
    "buy_seller_new_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgt eine Benachrichtig sobald Interesse von bestehenden Investor:innen an dem Kaufangebot besteht."])},
    "buy_seller_open_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemand ist bereit, dir die gewünschten Anteile zu verkaufen."])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot bearbeiten?"])},
    "delete_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot löschen"])},
    "private_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Verkaufsabschluss wird eine Vermittlungsgebühr von"])},
    "private_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vom Zahlungskonto abgebucht."])},
    "private_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf freigeben"])},
    "private_release_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald die Zahlung durchgeführt wurde, kannst du mit dem unten folgenden Button den Kauf der Anteile freigeben."])},
    "private_release_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf freigegeben"])},
    "private_release_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf freigegeben"])},
    "process_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktion von 5 Anteilen hat erfolgreich stattgefunden und sind jetzt in deinem Besitz."])},
    "purchase_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufangebot"])},
    "seller_released_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Verkäuer:in hat die Transaktion freigegeben. Die Anteile sind jetzt in deinem Besitz."])},
    "seller_release_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe der Verkäufer:in"])},
    "seller_release_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald auch die Verkäufer:in die Transaktion freigegeben hat, wechseln die Anteile die Besitzer:in."])},
    "sell_private_contact_first_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden folgende Kontaktdaten angegeben, damit mit der/die Verkäufer:in Kontakt aufnehmen kann bzgl. der Zahlungsabwicklung."])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweise"])},
    "transfer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an das Zahlungskonto:"])},
    "view_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment ansehen"])}
  },
  "ComingSoon": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Marktplatz"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App herunterladen"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite steht nur in der App zur Verfügung. Bitte lade die App herunter, um fortzufahren."])}
  },
  "CompanyRegistration": {
    "emailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail nicht gültig"])},
    "input_addressLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresse"])},
    "input_emailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "input_nameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "input_phoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "input_registerNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsregisternummer"])},
    "input_taxNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung als Unternehmen"])},
    "phoneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "primaryButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "secondaryButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doch als natürliche Person registrieren"])}
  },
  "ContinueOnMobile": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "headerBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsüberprüfung"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um investieren zu können, ist eine Identitätsüberprüfung anhand eines gültigen Ausweisdokuments erforderlich.\nNutze dazu die im Registrierungsprozess angegebene E-Mail-Adresse and das angegebene Passwort. Bitte führe diesen Schritt in unserer mobilen App durch. Scanne dazu den QR-Code mit der Kamera oder lade die App im AppStore oder PlayStore herunter."])}
  },
  "CountriesModal": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])}
  },
  "CreateAccount": {
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "goForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "Day": {
    "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
    "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
    "Saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
    "Sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
    "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
    "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
    "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])}
  },
  "DesktopSecurityAppIntegration": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe die Security App verbunden"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist noch keine Security App verbunden. Diese wird zum fortfahren benötigt."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Security App forfahren"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Security App herunterladen oder eine eigene verbinden"])}
  },
  "Documents": {
    "plantList_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagenliste"])}
  },
  "DownloadApp": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store öffnen"])},
    "mobileNotSupported_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Plattform ist derzeit nur auf dem Desktop verfügbar. Bitte öffnen Sie die Anwendung auf einem größeren Bildschirm."])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanne den QR Code mit einem Handy um die PLATFORM App herunterzuladen. "])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt App downloaden!"])}
  },
  "EnergyImpact": {
    "card_shareProductionPerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Anteil erzeugt etwa"])},
    "card_withElCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem e-Auto"])},
    "co2_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechnung erfolgt Beispielhaft lt. Formel der IBC Solar"])},
    "elCar_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Berechnung erfolgt beispielhaft unter der Annahme eines Normverbrauchs von 15,4kWh pro 100 km, was laut ADAC dem Verbrauch eines Tesla Model 3 entspricht (Stand: 14.04.2023)."])},
    "householdConsumption_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein durchschnittlicher österreichischer Haushalt verbraucht 4.415 kWh elektrische Energie pro Jahr (Quelle: Österreichs E-Wirtschaft, Stand 2023)."])},
    "modal_avgHouseholdConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauch eines durschnittlichen Haushalts mit 4.500 kWh."])},
    "modal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du berechnen, welchen Beitrag deine Investition zur Energiewende leistet und welche Möglichkeiten sich damit eröffnen."])},
    "modal_eCarDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit einem e-Auto fahren"])},
    "modal_expectedCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO₂ Ersparnis (in Tonnen)"])},
    "modal_expectedGeneratedEnergy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtlich erzeugte Energie p.a."])},
    "modal_lightBulbHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licht"])},
    "modal_numOfShares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Anteile"])},
    "modal_phoneChargingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre Handy laden"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Beitrag zu einer nachhaltigen Zukunft"])},
    "modal_tvHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fernsehen oder Netflix schaun"])},
    "modal_washMachineCircles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wäsche waschen"])},
    "modal_whereCanBeSpent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit könnte man auch"])},
    "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Jahr."])},
    "whereCanBeSpent_modalContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Stromverbrauch wird wie folgt angenommen:\nPro Waschgang: 0,75kWh, Pro gefahrenen 100km 15,4kWh, Pro Stunde Licht (60Watt), Jahresverbrauch Handyladen 5kWh, Pro Stunde Netflix 0,2kWh."])}
  },
  "Enum": {
    "AssetStage": {
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
      "preparing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing"])},
      "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzierungsphase"])},
      "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
      "secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel aktiv"])}
    },
    "BuildingConditionType": {
      "massive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Massiv"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau"])},
      "newDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau"])},
      "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altbau"])},
      "renovation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbruch – Neubau"])}
    },
    "ConstructionStatus": {
      "connectedToNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Netz"])},
      "installed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiert"])},
      "planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned"])},
      "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bau"])},
      "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand"])}
    },
    "ConstructionType": {
      "integrated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integriert"])},
      "landsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiflächen"])},
      "roof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufdach"])}
    },
    "ContractType": {
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["befristet"])},
      "notRented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht vermietet"])},
      "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unbefristet"])}
    },
    "HeatingType": {
      "central": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentralheizung"])},
      "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fernwärme"])},
      "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrische Heizung"])},
      "floorCentral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etagenheizung"])},
      "heatpump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wärmepumpe"])},
      "naturalGas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdgas"])},
      "oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdölheizung"])},
      "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar"])},
      "woodPellet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelletheizung"])}
    },
    "PowerPlantType": {
      "bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energieinfrastruktur"])},
      "photovoltaic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photovoltaik"])},
      "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicher"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind"])}
    },
    "PropertyType": {
      "apartmentBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau"])},
      "atticFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dachgeschoss Wohnung"])},
      "commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbe"])},
      "cooperativeFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genossenschaftswohnung"])},
      "demolitionNewConstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbruch – Neubau"])},
      "duplexHouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifamilienhaus"])},
      "familyHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfamilienhaus"])},
      "firstFloorFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etagenwohnung"])},
      "flat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
      "loft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loft"])},
      "loftConversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dachausbau"])},
      "partialFamilyHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus (Miteigentum)"])},
      "pentHouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penthaus"])},
      "rawAtticFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rohdachboden"])},
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer"])},
      "studioFlat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio"])}
    },
    "SecurityType": {
      "participationAtypicalSilent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atypisch stille Beteiligung"])},
      "participationKG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG Anteil"])},
      "participationRightDebtCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genussschein (Fremdkapital)"])},
      "participationRightDebtCapitalPartialSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genussschein (Fremdkapital, Teilverkauf)"])},
      "participationRightEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genussschein (Eigenkapital)"])},
      "participationRightEquityBuilderOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genussschein (Eigenkapital, Bauherrenmodell)"])},
      "purchaseWithManagementOutsourcing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf mit Auslagerung der Betriebsführung"])}
    },
    "TransactionMethods": {
      "applePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple Pay"])},
      "bankWire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])},
      "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditkarte"])},
      "directDebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofortüberweisung"])},
      "googlePay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Pay"])},
      "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])}
    },
    "TransactionStatuses": {
      "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
      "canceledOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniertes Angebot"])},
      "charged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingezahlt"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Init"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
      "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückerstattung"])},
      "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserviert"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich"])}
    },
    "TransactionTaxType": {
      "KapESt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitalertragsteuer (KapESt)*"])},
      "KESt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitalertragsteuer (KESt)*"])},
      "Kirchensteuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirchensteuer*"])},
      "Soli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solidaritätszuschlag*"])}
    },
    "TransactionTypes": {
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
      "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
      "charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung"])},
      "financing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financing"])},
      "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung"])},
      "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttung"])},
      "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])}
    }
  },
  "ExternalIdentification": {
    "backToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur App"])},
    "loginIchApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen mit ich."])},
    "orLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
    "proceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt fortfahren"])},
    "proceedIchApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgefüllt mit ich."])},
    "registerIchApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden mit ich."])}
  },
  "ExternalSignature": {
    "codeConfirmation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der eingegebene Code ist leider falsch."])},
    "codeConfirmation_send_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code erneut senden"])},
    "codeConfirmation_sms_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dokumente können nun signiert werden. Dazu haben wir einen Code an"])},
    "codeConfirmation_sms_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschickt."])},
    "codeConfirmation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signieren"])},
    "electronicSignatureInfo_failed_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Verifzierung ist etwas schiefgelaufen"])},
    "electronicSignatureInfo_failed_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "electronicSignatureInfo_failed_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "electronicSignatureInfo_pending_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überprüfung der Daten dauert länger"])},
    "electronicSignatureInfo_pending_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "electronicSignatureInfo_pending_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir melden uns, sobald die Accounteröffnung fortgesetzt werden kann."])},
    "electronicSignatureInfo_pending_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Marktplatz"])},
    "electronicSignatureInfo_pending_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen kurzen Augenblick..."])},
    "electronicSignatureInfo_skip_for_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später fortfahren"])},
    "electronicSignatureInfo_success_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die übermittelten Informationen werden gerade überprüft"])},
    "electronicSignatureInfo_success_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bitten um einen kurzen Moment..."])},
    "fourthlineRequirementsModal_gpsRequired_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort erneut freigeben"])},
    "fourthlineRequirementsModal_gpsRequired_errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten nicht auf den Standort zugreifen. Aus Sicherheitsgründen ist dies aber erforderlich, um mit der Verifizierung fortzufahren. Bitte überprüfe in den Einstellungen ob die App berechtigt ist auf den Standort zuzugreifen."])},
    "fourthlineRequirementsModal_gpsRequired_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheitsgründen muss der Standort erneut freigeben werden, um die Verifizierung abzuschließen."])},
    "fourthlineRequirementsModal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
    "fourthlineRequirementsModal_selfieRequired_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie erneut aufnehmen"])},
    "fourthlineRequirementsModal_selfieRequired_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheitsgründen muss erneut ein Selfie gemacht werden, um die Verifizierung abzuschließen."])},
    "partnerApp_signatureDocuments_firstItem_subtitle_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die"])},
    "partnerApp_signatureDocuments_firstItem_subtitle_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- App ist eine Plattform die von Brickwise Investment GmbH betrieben wird."])},
    "partnerApp_signatureDocuments_firstItem_title_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreiberin der"])},
    "partnerApp_signatureDocuments_firstItem_title_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- App"])},
    "signatureDocuments_firstItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die Nutzungsbedingungen sowie die Kundeninformationen & Risikohinweise der Plattformbetreiberin."])},
    "signatureDocuments_firstItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "signatureDocuments_firstItem_second_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeninformationen & Risikohinweise"])},
    "signatureDocuments_firstItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "signatureDocuments_fourthItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die Nutzungsbedingungen der Namirial S. p. A. und die Datenschutzerklärung der SafeNed-Fourthline B.V."])},
    "signatureDocuments_fourthItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "signatureDocuments_fourthItem_second_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "signatureDocuments_fourthItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifizierte elektronische Signatur"])},
    "signatureDocuments_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "signatureDocuments_secondItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die AGB und Kundeninformationen der Effecta GmbH."])},
    "signatureDocuments_secondItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen"])},
    "signatureDocuments_secondItem_second_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeninformationen"])},
    "signatureDocuments_secondItem_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Brickwise Investment GmbH ist vertraglich gebundener Vermittler gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt, die durch die Bundesanstalt für Finanzdienstleistungsaufsicht beaufsichtigt wird."])},
    "signatureDocuments_secondItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzdienstleistungsinstitut"])},
    "signatureDocuments_thirdItem_checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die Nutzungsbedingungen für Zahlungsdienste der Mangopay SA."])},
    "signatureDocuments_thirdItem_first_document_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahmenvertrag für Zahlungsdienste"])},
    "signatureDocuments_thirdItem_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ein- und Auszahlungen vornehmen zu können, wird ein persönliches Zahlungskonto bei Mangopay eröffnet."])},
    "signatureDocuments_thirdItem_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdienstleister"])},
    "signatureDocuments_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbarungen"])},
    "signatureVerificationInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung abschließen"])},
    "signatureVerificationInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte akzeptiere und signiere im nächsten Schritt unsere Vereinbarungen."])},
    "signatureVerificationInfo_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
    "verificationFinalModal_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account wurde erfolgreich verifiziert!"])},
    "verificationFinalModal_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viel Erfolg beim Investieren."])},
    "verificationFinalModal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt investieren"])}
  },
  "FinancialAdvisor": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Berater / Tippgeber"])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Berater/Tippgeber inkl. Firmenname"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie Ihren Berater oder Tippgeber bekanntgeben."])}
  },
  "FinancingOverview": {
    "currentlyInvested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisher investiert"])},
    "fundingLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundinglimit"])},
    "fundingThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundingschwelle"])}
  },
  "ForgotPasswordView": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigungscode"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bestätigen"])},
    "emailCheck_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bestätigen"])},
    "emailCheck_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bestätigen um das Passwort zurückzusetzen"])},
    "email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene E-Mail Adresse ist ungültig."])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbergen"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um das Passwort zurückzusetzen, muss die E-Mail Adresse des Accounts eingegeben werden."])},
    "info_code1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben einen Freigabecode an"])},
    "info_code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesendet, womit das Passwort zurücksetzt werden kann."])},
    "info_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lege ein neues Passwort mit mindestens 10 Zeichen und einem Großbuchstaben fest."])},
    "info_password_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle ein neues Passwort."])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "no_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keinen Code erhalten?"])},
    "password_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 10 Zeichen und ein Großbuchstabe"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "send_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut senden"])},
    "sent_again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigungscode wurde erneut gesendet!"])},
    "set_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort festlegen"])},
    "set_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort festlegen"])},
    "success_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das neue Passwort kann nun zum Einloggen verwendet werden."])},
    "success_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neues Passwort wurde festgelegt."])},
    "to_brickwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zum Login"])},
    "token_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der eingegebene Code ist falsch."])},
    "too_many_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheitsgründen haben wir diese Funktion verübergehend gesperrt. Bitte melde dich bei unserem Support."])}
  },
  "Forum": {
    "activeVotingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktives Voting"])},
    "addAttachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang hinzufügen"])},
    "closedVotes_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Votings"])},
    "compliant_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalte melden"])},
    "compliant_sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
    "compliant_textPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht an Betreiber..."])},
    "compliant_titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
    "createOptionModal_addNewFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang hinzufügen"])},
    "createOptionModal_descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "createOptionModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Option erstellen"])},
    "createOptionModal_publishOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option veröffentlichen"])},
    "createOptionModal_titlePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel der Option"])},
    "createVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting erstellen"])},
    "dateAtTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um"])},
    "downloads_file1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachverständigenprotokoll"])},
    "downloads_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbuch"])},
    "downloads_file3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzwertgutachten"])},
    "downloads_file4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "downloads_file5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietvertrag"])},
    "downloads_file6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsdatenblatt"])},
    "downloads_file7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "downloads_file8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformationsblatt"])},
    "downloads_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "forumTextArea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten."])},
    "headerMenu_closedVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Votings"])},
    "headerMenu_complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhalte melden"])},
    "headerMenu_downloads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloads"])},
    "headerMenu_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtlinien und Regeln"])},
    "headerMenu_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen erhalten"])},
    "rule1_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut zu wissen:"])},
    "rule1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Forum dient nur zur Kommunikation unter den Investor:innen."])},
    "rule2_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte halte dich an folgende Regeln:"])},
    "rule2_point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Diskriminierung und Diffamierung"])},
    "rule2_point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Störung der Diskussion"])},
    "rule2_point3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine falschen oder illegale Beiträge"])},
    "rule2_point4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Veröffentlichung von privaten Informationen"])},
    "rulesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zum Forum"])},
    "rules_footnote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen an Brickwise oder die Emittentin werden hier nicht beantwortet und können gerne jederzeit an support", "@", "brickwise.at gesendet werden."])},
    "rulesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor:innen Forum"])},
    "rulesSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Forum wir von der SPV_NAME bereitgestellt."])},
    "startVote_addFIle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhang hinzfuhen"])},
    "startVote_addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Option hinzufügen"])},
    "startVote_createOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIONEN ERSTELLEN"])},
    "startVote_goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "startVote_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Voting erstellen"])},
    "startVoteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Voting erstellen"])},
    "startVote_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "startVote_optionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "startVote_optionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel der Option"])},
    "startVote_submitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting veröffentlichen"])},
    "startVote_submitErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Bitte versuche es nochmal oder kontaktiere den Support."])},
    "startVote_submitFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald das Voting veröffentlicht wurde, wird es zu den Voting-Vorschlägen aufgenommen."])},
    "startVote_submitSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit das Voting zur Abstimmung gelangt, müssen mindestens 10% der Stimmanteile und 10% der Investor:innen innerhalb von 48 Stunden das Voting unterstützen."])},
    "startVote_theme1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschluss und Kündigung von Miet- oder Pachtverträgen"])},
    "startVote_theme2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung von Dienstleistern zur Verwaltung der Immobilie (z.B. Hausverwaltung)"])},
    "startVote_theme3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung von Dienstleistern zur Instandhaltung oder Instandsetzung der Immobilie (z.B. Handwerker)"])},
    "startVote_theme4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragung von Dienstleistern zur Vermittlung von Miet- oder Pachtverträgen (z.B. Makler)"])},
    "startVote_theme5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufnahme von Krediten zur Finanzierung von Instandhaltungen"])},
    "startVote_theme6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])},
    "startVote_voteDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "startVote_voteTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themengebiet auswählen"])},
    "startVote_voteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel des Votings"])},
    "startVote_voteTopic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WORÜBER SOLL ABGESTIMMT WERDEN?"])},
    "subVote_agreeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmen"])},
    "subVote_approval_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich stimme zu"])},
    "subVote_approval_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustimmung"])},
    "subVote_progressbarLabelText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit dieses Voting zur Abstimmung gelangt, müssen mindestens 10% der Stimmanteile und 10% der Investor:innendas Voting unterstützen."])},
    "subVote_progressbarLabelTextNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorschlag hat nicht die notwendigen 10% der Investor:innen überzeugen können."])},
    "subVote_progressbarLabelTextYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorschlag hat die notwendigen 10% der Investor:innen überzeugen können und wird somit zur Abstimmung gebracht."])},
    "voteDone_abstainedVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthaltene Stimmen"])},
    "voteDone_abstentionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich enthalte mich der Stimme"])},
    "voteDone_againstAllButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bin gegen alle Optionen"])},
    "voteDone_againstAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegen alle Optionen"])},
    "voteDone_agreementText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Emittentin wird die Anteilinhaber:innen informieren, sobald die gewünschte Maßnahme durchgeführt wurde."])},
    "voteDone_doneHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossenes Voting"])},
    "voteDone_ongoingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehendes Voting"])},
    "voteDone_rejectedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Voting wurde mehrheitlich abgelehnt."])},
    "voteFuture_addNewOptionButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Option hinzufügen"])},
    "voteOverview_agreeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting unterstützen"])},
    "voteOverview_suggestionDefaultText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit dieses Voting zur Abstimmung gelangt, müssen mindestens 10% der Stimmanteile und 10% der Investor:innen das Voting mit den unterstützen. Wird dieses Ziel erreicht, wird eine Abstimmung durchgeführt."])},
    "voteOverview_suggestionFailedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorschlag hat nicht die notwendigen 10% der Investor:innen überzeugen können."])},
    "voteOverview_suggestionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschlag für Voting"])},
    "voteOverview_suggestionSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⏰ Geöffnet"])},
    "voteOverview_suggestionSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Vorschlag hat die notwendigen 10% der Investor:innen überzeugen können und wird somit zur Abstimmung gebracht."])},
    "votingMessage_approvalReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["✅ Zustimmung erhalten"])},
    "votingMessage_completedIcon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚡️ Abgeschlossen"])},
    "votingMessage_noConsentReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🚫 Keine Zustimmung erhalten"])},
    "votingMessage_ongoingVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufendes Voting"])},
    "votingMessage_openUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⏰ Geöffnet"])},
    "votingMessage_proposalForVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschlag für Voting"])},
    "votingMessage_upcomingVoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehendes Voting"])},
    "votingOverview_futureVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEVORSTEHENDE VOTINGS"])},
    "votingOverview_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votings Übersicht"])},
    "votingOverview_runningVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAUFENDE VOTINGS"])},
    "votingOverview_suggestedVotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VORSCHLÄGE FÜR VOTINGS"])}
  },
  "ForumContent": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investor: innen Forum"])}
  },
  "General": {
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich zum"])},
    "nextPayout_quarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartalsweise"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet ab"])},
    "nextPayout_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])}
  },
  "GermanTaxIdModal": {
    "churchMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied der Kirche?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um in dieses Produkt investieren zu können, benötigen wir eine deutsche Steueridentifikationsnummer und Angaben zur Kirchenzugehörigkeit."])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültig"])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-ID"])},
    "primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In ein anderes Produkt investieren"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei diesem Produkt wird eine Steuer-ID benötigt"])}
  },
  "HeaderNotification": {
    "identification_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier, um mit der Accountverifizierung fortzufahren."])},
    "identification_waiting_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir informieren per E-Mail, sobald wir alle Daten überprüft haben."])},
    "registration_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier, um einen Account zu erstellen."])},
    "securityApp_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier, um eine Security App zu verbinden."])},
    "signature_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier, um den letzten Schritt der Accounterstellung abzuschließen."])}
  },
  "Ich möchte folgenden Betrag veranlagen:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbares Veranlagungskapital"])},
  "IdentificationExternal": {
    "verificationFailed_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, etwas ist schiefgelaufen!"])},
    "verificationFailed_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "verificationFailed_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "verificationFailed_red_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das von dir aufgenomme Foto (Selfie) ist zu hell. Bitte mache ein neues Foto auf dem du gut erkennbar bist."])},
    "verificationInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsverifizierung mit Ausweis"])},
    "verificationInfo_bold_fourthline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweisfoto und Selfie"])},
    "verificationInfo_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später fortfahren"])},
    "verificationInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind gesetzlich dazu verpflichtet die Identität unserer Kunden zu verifizieren."])},
    "verificationInfo_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt verifizieren"])},
    "verificationModal_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später erledigen"])},
    "verificationModal_fourthline_first_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass"])},
    "verificationModal_fourthline_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsprüfung"])},
    "verificationModal_fourthline_second_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
    "verificationModal_fourthline_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Rahmen der Verifizierung wird ein Selfie sowie ein Ausweisfoto aufgenommen, anhand dessen die Identität verifizieren können."])},
    "verificationModal_fourthline_third_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein"])},
    "verificationModal_idNow_first_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass"])},
    "verificationModal_idNow_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsprüfung"])},
    "verificationModal_idNow_second_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
    "verificationModal_idNow_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Verifizierung erfolgt mittels IDnow VideoIdent Verfahren. Im Rahmen des expertengestützten Video-Chats wird eines der nachfolgenden Ausweisdokumente benötigt:"])},
    "verificationModal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung starten"])}
  },
  "IdentificationPersonalInfo": {
    "address_city_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "address_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Adresse ist nicht vollständig."])},
    "address_houseNumber_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "address_onlyAustrianCitizens_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider können nur natürliche Personen mit Wohnsitz in Österreich registrieren."])},
    "address_postcode_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "address_street_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "address_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschrift"])},
    "already_provided_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte schließen Sie den Registrierungsprozess ab und eröffnen Sie ein Support-Ticket, falls Sie die Telefonnummer ändern möchten."])},
    "already_provided_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Telefonnummer wurde bereits hinterlegt."])},
    "autocompleteAddress_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "birthday_age_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Plattform ist nur für Personen über 18 Jahren zugänglich."])},
    "birthday_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TT"])},
    "birthday_empty_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gib das Geburtsdatum ein."])},
    "birthday_input_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geburtsdatum ist nicht korrekt."])},
    "birthday_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MM"])},
    "birthday_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "birthday_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JJJJ"])},
    "birthPlace_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
    "clearForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressdaten zurücksetzen"])},
    "duplicate_user_popup_error_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurde bereits ein Account eröffnet?"])},
    "enter_manually_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuelle Eingabe"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "goForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "ibanNumber_apiErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige IBAN (API)"])},
    "ibanNumber_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungskonto"])},
    "ibanNumber_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "ibanNumber_invalidIbanErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige IBAN"])},
    "ibanNumber_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte gib für zukünftige Auszahlungen den persönlichen IBAN an."])},
    "nationality_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "nationality_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatsangehörigkeit"])},
    "next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "phoneError_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte kontaktieren Sie den Support."])},
    "phoneError_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim speichern der Telefonnummer ist ein Fehler aufgetreten."])},
    "phoneNumber_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind nur Zahlen erlaubt."])},
    "phoneNumber_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Telefonnummer scheint nicht richtig zu sein."])},
    "phoneNumber_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer"])},
    "phoneNumber_modal_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support kontaktieren"])},
    "phoneNumber_modal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])},
    "phoneNumber_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Person kann nur ein Account eröffnet werden. Falls das Passwort vergessen wurde, kann dieses durch Klick auf \"Passwort vergessen\" auf der Login Seite zurückgesetzt werden. Alternativ kann auch der Support kontaktiert werden."])},
    "phoneNumber_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Handynummer wird bereits mit einem anderen Account verwendet."])},
    "phoneNumber_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer"])},
    "phoneWrong_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie die Telefonnummer und versuchen Sie es erneut."])},
    "phoneWrong_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Telefonnummer ist nicht korrekt."])},
    "requiredFiles_moneyLaundering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Rechtsgeschäft dient weder der Geldwäscherei noch der Terrorismusfinanzierung."])},
    "requiredFiles_my_own_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich führe diesen Account im eigenen Namen und auf eigene Rechnung."])},
    "requiredFiles_pep_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politisch exponierte Person (PEP)"])},
    "requiredFiles_pep_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine politisch exponierte Person (PEP) ist eine Person, die entweder selbst ein hochrangiges wichtiges öffentliches Amt (z.B. Politiker:in, Botschafter:in, Richter:in oberster Gerichte) ausübt bzw. ausgeübt hat, oder eine Person aus deren unmittelbarem persönlichen Umfeld (z.B. Familienmitglieder oder nahestehende Personen)."])},
    "requiredFiles_political_person_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bestätige, dass ich keine"])},
    "requiredFiles_political_person_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bin."])},
    "requiredFiles_political_person_underlined_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politisch exponierte Person"])},
    "requiredFiles_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung"])},
    "requiredFiles_us_resident_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FATCA Gesetz"])},
    "requiredFiles_us_resident_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgrund des in 2010 erlassenen \"FATCA\"-Gesetzes müssen U.S.-Personen jährlich ihre Finanzkonten außerhalb der USA melden. Gleichzeitig werden Finanzinstitute außerhalb der USA verpflichtet, U.S.-Personen zu erfassen. Aufgrund der im FATCA definierten Anforderungen können Investor:innen, welche der US-Steuerpflicht unterliegen, kein Investment tätigen."])},
    "requiredFiles_us_tax_resident_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich bestätige, dass ich nicht"])},
    "requiredFiles_us_tax_resident_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bin."])},
    "requiredFiles_us_tax_resident_underlined_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerpflichtig in den USA"])},
    "smallBusinessOwner_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist der Auftraggeber/Sind Sie Kleinunternehmer iSd § 19 UStG?"])},
    "smallBusinessOwner_respondNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "smallBusinessOwner_respondYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "socialNumber_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermögensberater"])},
    "socialNumber_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Berater/Tippgeber inkl. Firmenname"])},
    "socialNumber_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie ggf. Ihren Vermögensberater bekanntgeben."])}
  },
  "IdentificationRegister": {
    "brickwiseApp_marketing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Brickwise Investment GmbH wird meinen Namen und meine E-Mail-Adresse verarbeiten, um mir Marketing-Nachrichten per E-Mail zu eigenen oder ähnlichen Produkten zuzusenden. Sofern dies nicht gewünscht ist, können Marketing-Nachrichten mit dem nebenstehenden Toggle, in den Einstellungen oder direkt in jeder E-Mail abbestellt werden."])},
    "companyRegisterModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Möglichkeit sich als natürliche Person oder als Unternehmen zu registrieren."])},
    "companyRegisterModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als natürliche Person oder Unternehmen registrieren?"])},
    "companyRegisterModal_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Unternehmen registrieren"])},
    "companyRegisterModal_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als natürliche Person registrieren"])},
    "emailConfirmation_bold_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail bestätigen"])},
    "emailConfirmation_light_text_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben einen Bestätigungslink an"])},
    "emailConfirmation_light_text_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschickt."])},
    "emailConfirmation_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-App öffnen"])},
    "emailConfirmation_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine E-Mail erhalten?"])},
    "email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail-Adresse scheint ungültig zu sein."])},
    "email_privacy_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere"])},
    "email_privacy_info_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "email_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "notReceivedEmail_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support kontaktieren"])},
    "notReceivedEmail_first_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail wurde gesendet an:"])},
    "notReceivedEmail_first_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist die E-Mail-Adresse korrekt?"])},
    "notReceivedEmail_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine E-Mail erhalten?"])},
    "notReceivedEmail_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail erneut senden"])},
    "notReceivedEmail_second_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte die E-Mail im Spam Ordner gefunden werden, bitte markiere unsere E-Mail als kein Spam."])},
    "notReceivedEmail_second_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfe den Spam-Ordner"])},
    "notReceivedEmail_third_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann etwas dauern bis unsere E-Mail im Posteingang angekommen ist."])},
    "notReceivedEmail_third_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte einen kurzen Moment"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird von Brickwise Investment GmbH betrieben („Plattformbetreiberin“), eine vertraglich gebundene Vermittlerin gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt."])},
    "operator_info_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Plattform"])},
    "partnerApp_marketing_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Plattformbetreiberin wird meinen Namen und meine E-Mail-Adresse verarbeiten, um mir Marketing-Nachrichten per E-Mail zu eigenen oder ähnlichen Produkten zuzusenden. Sofern dies nicht gewünscht ist, können Marketing-Nachrichten mit dem nebenstehenden Toggle, in den Einstellungen oder direkt in jeder E-Mail abbestellt werden."])},
    "registration_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Unternehmen registrieren"])},
    "registration_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "registration_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "registration_name_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "registration_next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "registration_password_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "registration_password_requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestes 10 Zeichen haben."])},
    "registration_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "registration_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als natürliche Person"])},
    "toggle_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten erhalten"])},
    "web_Email_headerBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "web_Email_headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "web_NamePassword_headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name und Passwort"])},
    "web_NamePassword_headerStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "Imprint": {
    "alternative_conflict_resolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternative Streitbeilegungsstelle"])},
    "company_purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensgegenstand"])},
    "company_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenbuch"])},
    "conflict_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verein Schlichtung für Verbrauchergeschäfte"])},
    "conflict_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mariahilferstraße 103/1/18, 1060 Wien;"])},
    "conflict_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon +43 1 890 63 11,"])},
    "conflict_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax +43 1 890 63 11 99,"])},
    "conflict_text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag – Donnerstag: 09:00 – 17:00 Uhr, Freitag: 09:00 – 15:00."])},
    "conflict_text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["www.verbraucherschlichtung.at"])},
    "document_privacyPolicy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "legal_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen und Offenlegung nach § 25 MedG und § 5 RCG"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer:"])},
    "tax_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID-Nummer:"])}
  },
  "ImprintDetails": {
    "address_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amalienstraße 71, 2. RGB"])},
    "address_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["80799 München"])},
    "address_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
    "brickwise_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail: hello", "@", "brickwise.at"])},
    "director_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer: Marco Neumayer"])},
    "district_court": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amtsgericht München, HRB 269358 UID: DE347734642"])},
    "fifth_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store und das App Store-Logo sind Marken von Apple Inc.Google Play und das Google Play-Logo sind Marken von Google LLC."])},
    "fifth_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere rechtliche Hinweise:"])},
    "first_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenstand der Gesellschaft ist die Vermittlung von Finanzinstrumenten über eine Internetdienstleistungsplattform als vertraglich gebundener Vermittler ausschließlich für Rechnung und unter der Haftung eines Wertpapierinstitutes gemäß § 3 Abs. 2 WpIG."])},
    "first_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensgegenstand"])},
    "fourth_section_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei den Informationen auf dieser Website handelt es sich um Werbemitteilungen. Jeder Artikel auf der Website ist eine Marketingmitteilung und versteht sich als Anregungs- und Informationsmedium. Er erhebt keinen Anspruch auf umfassende Informationen und spiegelt lediglich die Meinung des/der Autor(s):in wider. Die Inhalte des Artikels dienen daher ausschließlich zur Information und stellt keine Anlage-, Rechts-, Steuer- oder sonstige Beratungadienstleistung dar. Bei den im Artikel dargebotenen Inhalten, Grafiken und Texten handelt es sich somit um keine Anlageempfehlung."])},
    "fourth_section_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kapitalanlage ist mit Risiken verbunden. Der Wert einer Kapitalanlage kann sowohl steigen als auch fallen. Es kann somit zu Verlusten des eingesetzten Kapitals kommen. Frühere Wertentwicklungen, Simulationen oder Prognosen sind kein verlässlicher Indikator für die künftige Wertentwicklung. Bitte beachte unsere Risikohinweise."])},
    "fourth_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europäische Online-Streitbeilegungsplattform"])},
    "partner_provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist eine Plattform betrieben von Brickwise Investment GmbH:"])},
    "second_section_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BaFin Registernummer: 80174029"])},
    "second_section_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Brickwise Investment GmbH ist vertraglich gebundener Vermittler im Sinne von § 3 (2) WpIG und wird bei der Vermittlung von Finanzinstrumenten gemäß § 2 (2) Nr. 3 WpIG ausschließlich für Rechnung und unter Haftung der Effecta GmbH, Florstadt, tätig.Die Brickwise Investment GmbH ist bei der BaFin als gebundener Vermittler gemeldet."])},
    "second_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis gem. § 3 (2) WplG"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen und Offenlegung gemäß § 5 TMG"])},
    "third_section_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["http://ec.europa.eu/consumers/odr/"])},
    "third_section_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Europäische Kommission hat unter"])},
    "third_section_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eine Europäische Online-Streitbeilegungsplattform (OS-Plattform) errichtet. Die OS-Plattform kann ein Verbraucher für die außergerichtliche Beilegung einer Streitigkeit aus Online-Verträgen mit einem in der EU niedergelassenen Unternehmen nutzen."])},
    "third_section_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Europäische Online-Streitbeilegungsplattform"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])}
  },
  "ImprintFooter": {
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATENSCHUTZ"])}
  },
  "Inbox": {
    "emptyHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Nachrichten vorhanden."])},
    "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Postfach ist für wichtige Nachrichten und relevante Informationen."])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inbox"])}
  },
  "InfoCards": {
    "calculator_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier geht’s zum Renditerechner"])},
    "calculator_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechne dein Investment & deine Renditechancen"])},
    "howItWorks_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So funktioniert’s"])},
    "howItWorks_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Rendite & Wertentwicklung?"])},
    "referral_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von jeder Empfehlung profitieren"])},
    "referral_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund:innen einladen & Bonus kassieren"])}
  },
  "IntroImages": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])}
  },
  "IntroSlide1": {
    "login_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])}
  },
  "Investment": {
    "abort_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion abbrechen"])},
    "annual_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung p.a."])},
    "asset_performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertentwicklung"])},
    "asset_performance_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem klick ich auf “Weiter\" bestätige ich, dass ich höchstens das Doppelte meines durschnittlichen, monatlichen Nettoeinkommens (über 12 Monate gerechnet), oder maximal 10% meines Finanzanlagevermögens investiere."])},
    "austrianSocialNumber_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmeberechtigt sind nur natürliche Personen, die ihren steuerlichen Wohnsitz in Österreich haben. Für die Abgabe der entsprechenden Erklärungen ist die Angabe der Steuernummer oder der Sozialversicherungsnummer erforderlich."])},
    "austrianSocialNumber_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-Nr oder SVNR (Österreich)"])},
    "austrianSocialNumber_orLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder"])},
    "austrianSocialNumber_socialNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialversicherungsnummer (NNNNTTMMJJ)"])},
    "austrianSocialNumber_taxNumberLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-Nr (Österreich)"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verfügbar"])},
    "bonus_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BONUSCODE"])},
    "brickwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "builderOwnerModelOverview_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindlich Investieren"])},
    "builderOwnerModelOverview_companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft"])},
    "builderOwnerModelOverview_infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Preis ist eine Netto-Preis. Mit der Rechnung wird auch die persönliche Mehrwertsteuer berechnet. Somit kann sich dieser Preis noch ändern. Nach dem Kauf wird eine Zahlungsaufforderung für die Überweisung des Investitionsbetrags ausgestellt."])},
    "builderOwnerModelOverview_investmentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invesitionsbetrag"])},
    "builderOwnerModelOverview_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile kaufen"])},
    "buildingInvestment_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbetrag"])},
    "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
    "buy_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile kaufen"])},
    "cancel_transaction_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktion abbrechen?"])},
    "code_bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonuscode einlösen"])},
    "continue_with_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
    "create_buy_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufangebot erstellen"])},
    "create_sell_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsangebot erstellen"])},
    "create_sell_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot signieren"])},
    "digitalSignature_emptyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir konnten keine Signatur erkennen"])},
    "effecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effecta GmbH"])},
    "file_checkbox_underline_fifth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosteninformationen"])},
    "file_checkbox_underline_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformationsblatt"])},
    "file_checkbox_underline_fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbraucherninformationen"])},
    "file_checkbox_underline_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "file_checkbox_underline_third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagebasisinformationsblatt"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "goToAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Account"])},
    "investmentAmount_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "investment_terms_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich akzeptiere die folgenden Dokumente und Informationsblätter:"])},
    "investment_terms_newsletter_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten erhalten"])},
    "investmentTerms_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "investment_terms_underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH ist ein  gebundener Vermittler gemäß § 3 (2) WpIG  der Effecta GmbH, Florstadt."])},
    "investor_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote von Investor:innen"])},
    "investor_modal_text_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier befinden sich alle offenen Verkaufsangebote. Alternativ kann auch ein eigenes Angebot erstellt werden."])},
    "investor_modal_text_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier befinden sich alle offenen Kaufangebote. Alternativ kann auch ein eigenes Angebot erstellt werden."])},
    "investor_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote von Investor:innen"])},
    "issuer_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote der Emittentin"])},
    "issuer_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Anteile direkt von der Emittentin zum Ausgabepreis erworben werden. Bei der Emittentin handelt es sich um die grundbücherliche Eigentümerin, die die Genussschein für diese Immobilie ausgibt."])},
    "issuer_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind nicht so viele Anteile verfügbar."])},
    "issuer_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote der Emittentin"])},
    "k_and_e_bottom_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Klick auf “Kauf fortsetzen\" bestätige ich, dass ich darüber informiert wurde, dass der Kauf des Produkts, aufgrund meiner bisherigen Erfahrungen und Kenntnisse, nicht meinem Anlageprofil entspricht. Dennoch entscheide ich mich für den Kauf dieses Produktes."])},
    "k_and_e_bottom_text_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben aktualisieren"])},
    "k_and_e_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf fortsetzen"])},
    "k_and_e_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angemessenheit beurteilt sich danach, ob die erforderlichen Kenntnisse und Erfahrungen vorhanden sind, um die Risiken im Zusammenhang mit der Art der Finanz- instrumente oder Wertpapierdienst- leistungen angemessen beurteilen zu können."])},
    "k_and_e_first_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemessenheit"])},
    "k_and_e_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Hinweise"])},
    "k_and_e_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir möchten auf folgende wichtige Punkte aufmerksam machen."])},
    "k_and_e_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgrund der Angaben, sind wir leider der Ansicht, dass dieses Produkt bzw. die Investitionshöhe nicht dem Anlageprofil entspricht."])},
    "k_and_e_second_text_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider wurden keine oder nur unvollständige Angaben zu den bisherigen Erfahrungen und Kenntnissen gemacht. Daher sind wir nicht in der Lage, die Angemessenheit der Investition zu überprüfen. Als Investor muss daher selbst die Entscheidung getroffen werden, ob das Produkt und die Investitionshöhe angemessen sind."])},
    "k_and_e_second_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse & Erfahrungen"])},
    "k_and_e_third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Investitionsbedingungen bzw. Basisinformationsblatt enthalten alle Informationen über die Ausgestaltung und Risikostruktur des Produkts."])},
    "k_and_e_third_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikostruktur des Produktes"])},
    "menu_buy_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile kaufen"])},
    "minumumAmount_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mindestzeichnungsvolumen für dieses Asset beträgt"])},
    "minumumAmount_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "noOffers_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ein neues Angebot, um Anteile von anderen Investor:innen zu kaufen."])},
    "noOffers_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angebote"])},
    "notaryAppointment_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage schicken"])},
    "notaryAppointment_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin auswählen"])},
    "notaryAppointment_infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Termin wird nun an das Notariat weitergeleitet, das sich innerhalb der nächsten 48 Stunden mit weiteren Anweisungen melden wird."])},
    "notaryAppointment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle einen der folgenden Notartermine aus"])},
    "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind nicht so viele Anteile verfügbar."])},
    "not_enough_money_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Geld auf dem Zahlungskonto."])},
    "not_enough_money_modal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskonto aufladen"])},
    "not_enough_money_modal_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "not_enough_money_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um das Zahlungskonto zu nutzen, muss dieses mit einer Überweisung vom persönlichen Bankkonto aufgeladen werden. Der persönliche IBAN ist unter Account ersichtlich. Überweisungen kommen normal innerhalb von zwei Banktagen an."])},
    "offerCard_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinn"])},
    "offerCard_max_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. verfügbar"])},
    "offerCard_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Anteil"])},
    "offerCard_possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglicher"])},
    "offerCard_price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis / Anteil"])},
    "offerCard_profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinn"])},
    "offerCard_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung p.a"])},
    "offerCard_rent_distribution_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttung pro Jahr"])},
    "offerCard_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteil"])},
    "offerCard_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "offerCard_yieldPotential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial (YYJ)"])},
    "offer_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsübersicht"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird von Brickwise Investment GmbH betrieben, eine vertraglich gebundene Vermittlerin gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt."])},
    "operator_info_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Plattform"])},
    "over_5k_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investition über € 5.000"])},
    "over_5k_modal_header_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf fortsetzen"])},
    "over_5K_modal_secondary_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "over_5k_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem klick ich auf “Weiter\" bestätige ich, dass ich höchstens das Doppelte meines durschnittlichen, monatlichen Nettoeinkommens (über 12 Monate gerechnet), oder maximal 10% meines Finanzanlagevermögens investiere."])},
    "overview_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH ist Betreiberin dieser Plattform und vertraglich gebundener Vermittler gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt."])},
    "partnerInvestmentSuccessScreen_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.partnerInvestmentSuccessScreen_button TODO"])},
    "partnerInvestmentSuccessScreen_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.partnerInvestmentSuccessScreen_text1 TODO"])},
    "partnerInvestmentSuccessScreen_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.partnerInvestmentSuccessScreen_text2 TODO"])},
    "payment_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutze das Zahlungskonto oder wähle eines der weiteren Zahlungsmöglichkeiten:"])},
    "paymentRequest_accountName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfänger"])},
    "paymentRequest_amountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "paymentRequest_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminvereinbarung beim Online-Notar"])},
    "paymentRequest_ibanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "paymentRequest_infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im nächsten Schritt werden Sie gebeten, einen Termin mit einem Online-Notar zu vereinbaren, um eine Vollmacht zu unterschreiben. "])},
    "paymentRequest_purposeOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])},
    "paymentRequest_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überweisen Sie Ihren Investitionsbetrag auf das folgende Konto:"])},
    "paymentRequest_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben die Dokumente für die Investition per E-Mail an Sie geschickt."])},
    "possible_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwarteter Verlust"])},
    "possible_profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglicher Gewinn"])},
    "priceInfo_10PercentHigher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gewünschte Preis ist über 10% höher als der durchschnittliche Preis der letzten 30 Tage."])},
    "priceInfo_10PercentLower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gewünschte Preis ist über 10% niedriger als der durchschnittliche Preis der letzten 30 Tage."])},
    "priceInfo_20Percent_BuyModal_mainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf fortsetzen"])},
    "priceInfo_20Percent_BuyModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis bestätigen"])},
    "priceInfo_20PercentHigher_BuyModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gewünschte Preis ist über 20% höher als der durchschnittliche Preis der letzten 30 Tage. Ich bestätige, dass ich zu diesem Preis kaufen möchte."])},
    "priceInfo_20PercentHigher_SellModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der gewünschte Preis ist über 20% höher als der durchschnittliche Preis der letzten 30 Tage. Ich bestätige, dass ich zu diesem Preis verkaufen möchte."])},
    "priceInfo_20PercentLower_BuyModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angebotene Preis ist über 20% niedriger als der durchschnittliche Preis im letzten Monat. Ich möchte den Kauf trotzdem fortsetzen möchtest."])},
    "priceInfo_20PercentLower_SellModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angebotene Preis ist über 20% niedriger als der durchschnittliche Preis im letzten Monat. Ich möchte trotzdem fortfahren."])},
    "priceInfo_20Percent_Modal_closeButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "priceInfo_20Percent_SellModal_mainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf fortsetzen"])},
    "priceInfo_20Percent_SellModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufspreis bestätigen"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis / Anteil"])},
    "purchase_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERMITTLUNGSGEBÜHR"])},
    "purchaseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Klick auf “Kauf fortsetzen” bestätige ich, dass ich darüber informiert wurde, dass der Kauf des Produktes, aufgrund meiner bisherigen Erfahrungen und Kenntnisse, nicht meinem Anlageprofil entspricht. Dennoch entscheide ich mich für den Kauf dieses Produktes."])},
    "purchase_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufangebot"])},
    "purchase_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAUFPREIS"])},
    "purchase_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufübersicht"])},
    "realpartAccountOpening_firstCheckbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_firstCheckbox_label TODO"])},
    "realpartAccountOpening_secondCheckbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_secondCheckbox_label TODO"])},
    "realpartAccountOpening_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_subtitle TODO"])},
    "realpartAccountOpening_thirdCheckbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_thirdCheckbox_label TODO"])},
    "realpartAccountOpening_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_title TODO"])},
    "realpartAccountOpening_toggleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment.realpartAccountOpening_toggleLabel TODO"])},
    "realPart_KE_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angemessenheit beurteilt sich danach, ob die Risikobereitschaft, der Veranlagungshorizont und das verfügbare Veranlagungskapital mit der beabsichtigten Investition übereinstimmen.\n\nAufgrund der Angaben sind wir der Ansicht, dass dieses Produkt bzw. die Investitionshöhe nicht dem Anlageprofil entspricht."])},
    "realPart_KE_firstTile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemessenheit"])},
    "realPart_KE_firstTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemessenheit"])},
    "realPart_KE_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Hinweise"])},
    "realPart_KE_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir weisen auf folgende wichtige Punkte hin:"])},
    "realPart_KE_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Informationsblatt und die Risikohinweise enthalten alle Informationen über die Ausgestaltung und Risikostruktur des Produkts."])},
    "realPart_KE_secondTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikostruktur des Produktes"])},
    "remove_code_bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonuscode entfernen"])},
    "rental_income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
    "rental_payout_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
    "rental_payout_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedes Monat bzw. immer dann, wenn der/die Mieter:in der Immobilie die Miete bezahlt hat, erhältst du deinen Anteil in Form einer laufenden Rendite auf dein Zahlungskonto überwiesen und wir benachrichtigen dich in der App. Die genaue Berechnung des Mietüberschusses findest du bei jeder Immobilie unter dem Menüpunkt Mietübersicht."])},
    "rental_return_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Die erwartete Ausschüttung berechnet sich auf Basis des Netto-Mietüberschusses über die im Mietvertrag vereinbarte Nettomiete und kommt jeweils nur zur Auszahlung, wenn der/die Mieter:in diese bezahlt hat. "])},
    "rental_return_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung p.a."])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Produkte mit einem Fremdkapitalanteil können sich aufgrund der Hebelwirkung (Leverage) überproportional sowohl positiv als auch negativ entwickeln. Wir weisen ausdrücklich darauf hin, dass vergangene Entwicklungen und Prognosen kein zuverlässiger Indikator für künftige Wertentwicklungen sind und es sich bei den Renditeangaben lediglich um kalkulierte Werte handelt."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "returnOnEquityModal_title_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittleres Szenario"])},
    "sale_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERKAUFSPREIS"])},
    "sales_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsangebot"])},
    "sales_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsübersicht"])},
    "scenarioDocument_label_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir möchten auf die von der Emittentin kalkulierten"])},
    "scenarioDocument_label_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "scenarioDocument_label_third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hinweisen."])},
    "secondaryOfferCard_potentialEarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglicher Gewinn:"])},
    "secondaryOfferCard_sharesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl:"])},
    "secondaryOfferCard_totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt:"])},
    "security_app_action_sheet_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren"])},
    "security_app_action_sheet_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf doch nicht signieren?"])},
    "security_app_action_sheet_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf abbrechen"])},
    "security_app_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen"])},
    "security_app_buy_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App, um den Kauf zu signieren."])},
    "security_app_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "security_app_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird eine Security App benötigt, um einerseits die digitalen Anteile sicher verwahren zu können und andererseits, um alle Transaktionen von digitalen Anteilen zu signieren. Verwende dafür entweder die empfohlene Security App oder erstelle anhand der API Dokumentation eine eigene Security App."])},
    "security_app_sell_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App, um den Verkauf zu signieren."])},
    "sell_bottom_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Benachrichtigung erfolgt, sobald es eine Kaufanfrage gibt."])},
    "sell_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf signieren"])},
    "sell_offer_total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESAMTBETRAG"])},
    "sell_overview_bottom_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Benachrichtigung erfolgt, sobald ein Käufer die Transaktion signiert hat. Der Gesamterlös wird anschließend auf das Zahlungskonto gutgeschrieben."])},
    "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERKAUFSPREIS"])},
    "sell_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile verkaufen"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "shares_small": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "signDocuments_attorneyContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollmacht zum Betriebsführungsvertrag"])},
    "signDocuments_attorneyContractSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe die Vollmacht"])},
    "signDocuments_operatingAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsvertrag"])},
    "signDocuments_operatingAgreementSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe den Betriebsvertrag"])},
    "signDocuments_purchaseContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufvertrag"])},
    "signDocuments_purchaseContractSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe den Kaufvertrag"])},
    "signDocuments_subtenantAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untermietervertrag"])},
    "signDocuments_subtenantAgreementSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte unterschreibe den Untermietvertrag"])},
    "signDocuments_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document signieren"])},
    "sign_purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf signieren"])},
    "successScreen_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Marktplatz"])},
    "successScreen_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Termin wird mit dem Notariat vereinbart, das sich innerhalb der nächsten 48 Stunden mit weiteren Anweisungen bei Ihnen melden wird."])},
    "successScreen_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin vereinbart"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESAMTBETRAG"])},
    "total_revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESAMTERLÖS"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])}
  },
  "InvestmentBonus": {
    "btn_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Marktplatz"])},
    "bullet1_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 100 Bonus"])},
    "bullet1_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bei € 1.000 investment"])},
    "bullet2_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 550 Bonus"])},
    "bullet2_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bei € 5.000 investment"])},
    "bullet3_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 1.200 Bonus"])},
    "bullet3_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bei € 10.000 investment"])},
    "durationInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.12.2022."])},
    "durationInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot gültig bis:"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEBURTSTAGBONUS"])},
    "underline_buton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmebedingungen"])}
  },
  "InvestmentDetails": {
    "allScenarios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Szenarien"])},
    "builderOwnerModel_sharePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stückelung"])},
    "calculationAndRentalOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulation und Mietübersicht"])},
    "calculationAndRentalOverview_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Einnahmenübersicht und Kalkulation."])},
    "collateral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besicherung"])},
    "collateralModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besicherung"])},
    "constructionStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtlicher  Baubeginn"])},
    "debtFinancing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdfinanzierung"])},
    "distribution_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen"])},
    "document_investmentBasicInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformationsblatt"])},
    "document_investmentConditions_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "document_investmentInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsdatenblatt"])},
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit"])},
    "endless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["endlos"])},
    "expected_rent_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet ab"])},
    "financial_instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beteiligungsart"])},
    "financialInstrumentModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Produkte können rechtlich unterschiedlich ausgestaltet sein. Diese wiederum können unterschiedliche Ausprägungen hinsichtlich Laufzeit, Kündigungsrechten, Übertragbarkeit etc. haben. In den Bbedingungen finden sich weitere klärende Informationen. \n"])},
    "financialInstrumentModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beteiligungsart"])},
    "header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Infos"])},
    "leverageOnDay_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "leverageVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "marketCapitalization_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "marketCapitalizationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Marktkapitalisierung berechnet sich auf Basis der ingesamt verfügbaren Anteile multipliziert mit dem letzten Preis zu dem ein Anteil am Marktplatz gehandelt wurde."])},
    "marketCapitalizationModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "mediumScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittleres"])},
    "mortgage_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grundbücherliche Besicherung ausstehend"])},
    "mortgage_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbücherlich besicherter Genussschein"])},
    "object_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt"])},
    "optimisticScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimitischs"])},
    "originalLoanAmount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprünglicher  Darlehensbetrag"])},
    "outstandingLoanAmount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offener Darlehensbetrag"])},
    "overallSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtanlage"])},
    "periodConsiderationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Betrachtungszeitraum gibt an, für welchen Zeitraum das Renditepotenzial des investierten Eigenkapitals berechnet wird."])},
    "periodConsiderationModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrachtungszeitraum"])},
    "periodUnderConsideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrachtungszeitraum"])},
    "powerPlant_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungsübersicht und Kalkulation"])},
    "promotionalLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förderdarlehen"])},
    "realEstate_calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietübersicht und Kalkulation"])},
    "realPart_totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtinvestitionssumme"])},
    "rentalStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraussichtlicher  Mietbeginn"])},
    "rentIndexation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miet­indexierung"])},
    "repaymentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlungsbetrag"])},
    "returnPotential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendite­potenzial"])},
    "returnPotentialScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "returnPotentialScenario_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die nachfolgende Übersicht zeigt, wie unterschiedliche Wertentwicklungen das Renditepotenzial beeinflussen. In allen Szenarien wird eine Mietindexierung und verschiedene Wertentwicklungsszenarien angenommen."])},
    "stressScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stress"])},
    "taxInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerliche Hinweise"])},
    "totalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtnutzfläche"])},
    "total_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile Gesamt"])},
    "totalVolume_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtvolumen"])},
    "totalVolumeModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Marktkapitalisierung berechnet sich auf Basis der ingesamt verfügbaren Anteile multipliziert mit dem letzten Preis zu dem ein Anteil am Marktplatz gehandelt wurde. Der Fremdkapitalanteil gibt den offenen Darlehnsbetrag an."])},
    "totalVolumeModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtvolumen"])},
    "valueDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert­entwicklung"])},
    "yearsSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])}
  },
  "InvestmentInfo": {
    "about_the_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜBER DIE EMITTENTIN"])},
    "available_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Anteile"])},
    "debt_ratio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fremdkapitalanteil"])},
    "document_investmentBasicInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformationsblatt"])},
    "document_investmentConditions_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "document_investmentInformation_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsdatenblatt"])},
    "expected_rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen"])},
    "expected_rent_date_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet ab"])},
    "financial_instrument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzinstrument"])},
    "investment_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Infos"])},
    "isin_box_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN und Anteilsbezeichnung"])},
    "isin_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN kopiert"])},
    "isin_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ISIN, kurz für International Securities Identification Number, ist eine internationale Kennnummer durch die sich Wertpapiere eindeutig identifizieren lassen."])},
    "KEStLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer"])},
    "KEStValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungen unterliegen der KESt, welche durch die Emittentin abgeführt wird."])},
    "maturityDate_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeitende"])},
    "mortgage_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grundbücherliche Besicherung ausstehend"])},
    "mortgage_info2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundbücherlich besicherter Genussschein"])},
    "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "promotionalLoan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förderdarlehen"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung p.a."])},
    "rent_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Ausschüttung"])},
    "repaymentAmount_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückzahlungsbetrag pro Anteil zum Laufzeitende"])},
    "shares_on_blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile auf der Blockchain"])},
    "total_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile Gesamt"])},
    "transaction_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionkosten"])},
    "value_paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WERTPAPIER"])},
    "value_paper_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Emittentin des Wertpapiers ist grundbücherliche Eigentümerin der Immbobilie. Das ausgegebenen Wertpapiere sind Genussscheine und auf der Blockchain Brickprotocol digitalisiert."])},
    "value_paper_text_bambus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Emittentin ist grundbücherliche Eigentümerin des Miteigentumanteils der gegenständlichen Immobilie. Die ausgegebenen Wertpapier sind Genussscheine und auf der Blockchain Brickprotocol digitalisiert. Die Laufzeit des Wertpapiers ist unbegrenzt. Neben ordentlichen Kündigungsmöglichkeiten (nach 15 Jahren) bestehen insbesondere auch außerordentliche Kündigungsmöglichkeiten des Emittenten, wenn der Miteigentümer den Miteigentumsanteil zum anteiligen Verkehrswert zurückkauft (jederzeit möglich) oder der Miteigentümer seinen Verpflichtungen aus der Miteigentümervereinbarung nicht nachkommt und der Miteigentumsanteil oder die ganze Immobilie durch die Emittentin an wen auch immer verkauft wird. Siehe dazu auch insbesondere Ziffer 10.3. der Investitionsbedingungen."])}
  },
  "InvestmentInfoFooter": {
    "basic_info_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisinformationsblatt"])},
    "information_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationsdatenblatt"])},
    "investment_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWNLOADS"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Die erwartete Ausschüttung berechnet sich auf Basis des Netto-Mietüberschusses über die im Mietvertrag vereinbarten Nettomiete und kommt jeweils nur zur Auszahlung, wenn der/die Mieter:in diese bezahlt hat."])}
  },
  "InvestmentOverviewBox": {
    "builderOwnerModel_totalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtinvestment"])},
    "total_rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen ges."])},
    "total_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio Marktwert"])},
    "value_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertänderung"])}
  },
  "Investments": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufangebot"])},
    "buy_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT Anteile"])},
    "buy_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT Anteil"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "investmentLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Investment"])},
    "price_per_share_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["je"])},
    "revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
    "sale_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzierungsphase"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsangebot"])},
    "sell_multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT Anteile"])},
    "sell_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AMOUNT Anteil"])},
    "webMyInvestmentsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Investments"])},
    "webNoInvestmentsPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden zukünftige Investments gelistet."])},
    "webNoOpenOffersPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine offenen Angebote"])},
    "webOpenOffersSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Angebote"])}
  },
  "IssuerMarketing": {
    "consent_info_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine obigen Einwilligungen zu Mitteilungen sind für den Kaufabschluss nicht notwendig und ich kann diese jederzeit mit Wirkung für die Zukunft gegenüber"])},
    "consent_info_fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([") widerrufen."])},
    "consent_info_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["postalisch ("])},
    "consent_info_third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([") oder per E-Mail ("])},
    "dataTransfer_consent_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich willige ein, dass"])},
    "dataTransfer_consent_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, Adresse, E-Mail, Telefonnummer und Kaufverhalten (Anzahl und Art der gekauften Anteile) an die"])},
    "dataTransfer_consent_third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["übermittelt, damit diese mich per E-Mail und Telefon zu Marketingzwecken betreffend eigene oder ähnliche Produkte kontaktiert."])},
    "dataTransfer_consent_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktaufnahme durch"])},
    "email_consent_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die"])},
    "email_consent_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird meinen Namen und meine E-Mail-Adresse verarbeiten, um mir Marketing-Nachrichten per E-Mail zu eigenen oder ähnlichen Produkten zuzusenden. Sofern Sie dies nicht möchten, entfernen Sie das Häkchen in der nebenstehenden Checkbox oder klicken Sie auf den Abmeldelink am Ende einer erhaltenen Marketing-Nachricht. \n"])},
    "email_consent_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten erhalten"])},
    "main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH ist ein  gebundener Vermittler gemäß § 3 (2) WpIG  der Effecta GmbH, Florstadt."])},
    "phone_marketing_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich willige ein, dass"])},
    "phone_marketing_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meinen Namen und meine Telefonnummer verarbeitet, um mich telefonisch zur Bewerbung eigener oder ähnlicher Produkte zu kontaktieren."])},
    "phone_marketing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche telefonische Kontaktaufnahme"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "profiling_marketing_first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich willige ein, dass"])},
    "profiling_marketing_second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meinen Namen, meine E-Mail-Adresse sowie mein Verhalten in der App verarbeitet, um mir darauf basierend personalisierte (auf meinem Verhalten in der App basierende) Marketing-Nachrichten zu eigenen oder ähnlichen Produkten per E-Mail zuzusenden."])},
    "profiling_marketing_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalisierte Nachrichten („Profiling“)"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen der"])}
  },
  "IssuerSecurity": {
    "about_issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über die Emittentin"])},
    "blockchain_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile auf der Blockchain"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufer"])},
    "description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Emittentin des Wertpapiers ist grundbücherliche Eigentümerin der Immbobilie. Das ausgegebenen Wertpapiere sind Genussscheine und auf der Blockchain Brickprotocol digitalisiert."])},
    "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ISIN, kurz für International Securities Identification Number, ist eine internationale Kennnummer durch die sich Wertpapiere eindeutig identifizieren lassen."])},
    "investmentCompany_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beteiligungsgesellschaft"])},
    "isin_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN kopiert"])},
    "isin_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN und Anteilsbezeichnung"])},
    "shareNameISIN_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN und Anteilsbezeichnung"])},
    "shareNameISIN_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISIN und Anteilsbezeichnung"])},
    "shareName_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteilsbezeichnung"])},
    "shareName_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteilsbezeichnung"])}
  },
  "KnowledgeAndExperience": {
    "experienceInfo_fifthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investmentfonds"])},
    "experienceInfo_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktien"])},
    "experienceInfo_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genussscheine"])},
    "experienceInfo_modal_fistItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis 3 Jahre"])},
    "experienceInfo_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie lange bestehen die Erfahrungen mit diesem Finanzprodukt?"])},
    "experienceInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 bis 5 Jahre"])},
    "experienceInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["über 5 Jahre"])},
    "experienceInfo_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleihen"])},
    "experienceInfo_sixthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
    "experienceInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe Erfahrungen mit folgenden Finanzprodukten:"])},
    "experienceInfo_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digitale Wertpapiere"])},
    "experienceInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrungen"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "investmentServicesInfo_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EIGENSTÄNDIG / BERATUNGSFREI"])},
    "investmentServicesInfo_modal_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
    "investmentServicesInfo_modal_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["über 5 Jahre"])},
    "investmentServicesInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis 3 Jahre"])},
    "investmentServicesInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 bis 5 Jahre"])},
    "investmentServicesInfo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "investmentServicesInfo_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIT ANLAGEBERATUNG"])},
    "investmentServicesInfo_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MIT VERMÖGENSVERWALTUNG"])},
    "investmentServicesInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehen Erfahrungen mit Wertpapierdienstleistungen?"])},
    "keInfo_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse und Erfahrungen"])},
    "keInfo_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind gesetzlich dazu verpflichtet, genaue und aktuelle Informationen über die Kenntnisse und Erfahrungen unserer Kunden mit verschiedenen Finanzinstrumenten und Wertpapierdienstleistungen einzuholen."])},
    "knowledgeInfo_fifthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktien"])},
    "knowledgeInfo_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investmentfonds"])},
    "knowledgeInfo_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genussscheine"])},
    "knowledgeInfo_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleihen"])},
    "knowledgeInfo_sixthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["digitale Wertpapiere"])},
    "knowledgeInfo_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe Kenntnisse über folgende Finanzprodukte:"])},
    "knowledgeInfo_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
    "knowledgeInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse"])},
    "later_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angaben machen"])},
    "next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "skipFlowModal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "skipFlowModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angaben machen?"])},
    "skipFlowModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Klick auf “Weiter\" bestätige ich, dass ich mir bewusst bin, dass durch meine fehlenden Angaben keine Prüfung auf Angemessenheit meines Wissens in Bezug auf Finanzprodukte möglich ist und ich auf eigenes Risiko handle."])},
    "transactionInvestInfo_modal_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 0 bis € 3.000"])},
    "transactionInvestInfo_modal_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["über € 10.000"])},
    "transactionInvestInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 3.001 bis € 5.000"])},
    "transactionInvestInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€ 5.001 bis € 10.000"])},
    "transactionInvestInfo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "transactionInvestInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher Betrag je Transaktion:"])},
    "transactionsAmountInfo_modal_firstItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine"])},
    "transactionsAmountInfo_modal_fourthItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr als 10"])},
    "transactionsAmountInfo_modal_secondItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis 5"])},
    "transactionsAmountInfo_modal_thirdItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis 10"])},
    "transactionsAmountInfo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "transactionsAmountInfo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Transaktionen pro Jahr:"])},
    "webHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse und Erfahrungen"])}
  },
  "KnowledgeAndExperienceNew": {
    "basicQuestions_bonds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anleihen"])},
    "basicQuestions_certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikate"])},
    "basicQuestions_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrung"])},
    "basicQuestions_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahrungen & Kenntnisse"])},
    "basicQuestions_kgParticipationAsBuildersModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG-Beteiligung als Bauherrenmodell"])},
    "basicQuestions_knowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse"])},
    "basicQuestions_optionsAndFutures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionen und Futures"])},
    "basicQuestions_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "basicQuestions_savingsAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sparbuch, Bausparvertrag"])},
    "basicQuestions_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "basicQuestions_stocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktien und Fonds"])},
    "basicQuestions_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe Kenntnisse und Erfahrungen mit folgenden Produkten:"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "investHorizon_1_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzfristig 1-4 Jahre"])},
    "investHorizon_20+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langfristig mehr als 20 Jahre"])},
    "investHorizon_6_19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittelfristig 6-19 Jahre"])},
    "investHorizon_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Veranlagung für folgenden Zeithorizont machen?"])},
    "investHorizon_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranlagungshorizont"])},
    "investHorizon_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "investHorizon_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "investHorizon_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Veranlagung für folgenden Zeithorizont machen?"])},
    "investmentCapital_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbares Veranlagungskapital"])},
    "investmentCapital_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veranlagungskapital"])},
    "investmentCapital_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "investmentCapital_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "investmentCapital_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte folgenden Betrag veranlagen:"])},
    "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse und Erfahrungen"])},
    "riskAppetite_dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bitten um Bekanntgabe der Risikobereitschaft:"])},
    "riskAppetite_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobereitschaft"])},
    "riskAppetite_option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringe Risikobereitschaft (z.B. Sparbuch, Bausparvertrag)"])},
    "riskAppetite_option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittlere Risikobereitschaft (z.B. Anleihen)"])},
    "riskAppetite_option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhere Risikobereitschaft (z.B. Aktien, Fonds)"])},
    "riskAppetite_option4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spekulativ (z.B. KG-Beteiligung als Bauherrenmodell, Zertifikate)"])},
    "riskAppetite_option5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochspekulativ (z.B. Optionen, Futures)"])},
    "riskAppetite_primaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "riskAppetite_secondaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angeben machen"])},
    "riskAppetite_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bitten um Bekanntgabe der Risikobereitschaft:"])},
    "skipFlowModal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "skipFlowModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angaben machen?"])},
    "skipFlowModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Klick auf “Weiter\\\" bestätige ich, dass ich mir bewusst bin, dass durch meine fehlenden Angaben keine Prüfung auf Angemessenheit meines Wissens in Bezug auf Finanzprodukte möglich ist und ich auf eigenes Risiko handle."])}
  },
  "LinkNewSecurityApp": {
    "box1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich hab mein altes Gerät verloren"])},
    "box2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich hab ein neues Gerät"])},
    "box3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich hab die App neu installiert"])},
    "box4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich will ein weiteres Gerät verknüpfen"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieso möchtest du eine neue Security App verknüpfen?"])}
  },
  "LocationDetails": {
    "about_the_region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜBER DIE REGION"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAGE"])},
    "object_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details zur Lage"])}
  },
  "LoginView": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account wechseln?"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort und E-Mail Adresse stimmen nicht überein."])},
    "error_recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das eingegebene Passwort ist leider falsch."])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergessen?"])},
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbergen"])},
    "incorrect_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene E-Mail Adresse ist nicht gültig. "])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])},
    "use_face_biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Face ID entsperren"])},
    "use_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Passwort entsperren"])},
    "use_touch_biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Touch ID entsperren"])}
  },
  "MarketingNewsLetterModal": {
    "default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt nicht"])},
    "main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen aktivieren"])},
    "privacy_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere"])},
    "privacy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte regelmäßig Neuigkeiten"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten erhalten"])}
  },
  "Marketplace": {
    "all_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "apartments_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnimmobilien"])},
    "business_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbe"])},
    "durationInfoModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte können eine definierte Laufzeit (mit Fälligkeitsdatum/Laufzeitende) haben oder als endlose Finanzprodukte gestaltet sein. Eine Kombination mit unterschiedlichen Kündigungsrechten ist ebenso möglich. Details sind in den Investitionsbedingungen unter Investment Infos zu finden. Anteile bestimmter Produkte können auch am Marktplatz anderen Investor:innen zum Verkauf angeboten werden. "])},
    "durationInfoModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit"])},
    "favorite_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoriten"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab"])},
    "fundedAssetsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Objekte"])},
    "inConstruction_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bau"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste"])},
    "list_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Plattform wird von Brickwise Investment GmbH betrieben, die vertraglich gebundener Vermittler gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt, ist."])},
    "list_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecke unsere Objekte"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
    "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktplatz"])},
    "newAssetsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Objekte"])},
    "operator_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird von Brickwise Investment GmbH betrieben, eine vertraglich gebundene Vermittlerin gemäß § 3 (2) WpIG der Effecta GmbH, Florstadt."])},
    "operator_info_first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Plattform"])},
    "powerplantPortfolioConnectedToNetwork_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am Netz"])},
    "powerplantPortfolioInstalled_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiert"])},
    "powerplantPortfolioPlanning_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bau"])},
    "powerplantPortfolioProject_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektiert"])},
    "pre_sales_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzierungsphase"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])},
    "rental_return_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Die erwartete Ausschüttung berechnet sich auf Basis des Netto-Mietüberschusses über die im Mietvertrag vereinbarte Nettomiete und kommt jeweils nur zur Auszahlung, wenn der/die Mieter:in diese bezahlt hat. "])},
    "rental_return_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung p.a."])},
    "rental_yield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung"])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Produkte mit einem Fremdkapitalanteil können sich aufgrund der Hebelwirkung (Leverage) überproportional sowohl positiv als auch negativ entwickeln. Wir weisen ausdrücklich darauf hin, dass vergangene Entwicklungen und Prognosen kein zuverlässiger Indikator für künftige Wertentwicklungen sind und es sich bei den Renditeangaben lediglich um kalkulierte Werte handelt."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "returnOnEquityModal_title_second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittleres Szenario"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "stock_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])}
  },
  "Menu": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "inbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
    "investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "login_popup_button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])},
    "login_popup_button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe bereits einen Account"])},
    "login_popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle einen Account, um zu investieren."])},
    "marketplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktplatz"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])}
  },
  "Month": {
    "April": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "August": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "December": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])},
    "February": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
    "January": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
    "July": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
    "June": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
    "March": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
    "May": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "November": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "October": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
    "September": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])}
  },
  "MyInvestment": {
    "available_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Anteile"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis pro Anteil"])},
    "average_price_secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ø Kaufpreis pro Anteil"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag*"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage*"])},
    "duration_purchase_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibende Zeit"])},
    "erw_rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung"])},
    "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORUM DER INVESTOR:INNEN"])},
    "forum_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum öffnen"])},
    "forum_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mitbestimmungsrechte können über das Forum ausgeübt werden."])},
    "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noch"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere laden"])},
    "my_investment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Investment"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
    "past_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERGANGENE TRANSAKTIONEN"])},
    "popup_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Was ist die Finanzierungsphase?"])},
    "popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Die Finanzierungsphase bezeichnet den Zeitraum, in der neue Anteile das erste Mal von Investor:innen gekauft werden können. Nachdem alle Anteile verkauft wurden, gilt die Finanzierungsphase offiziell als beendet. Ob es sich bei einem Objekt um einen erstmaligen Verkauf (Emission) handelt, erkennst du auch an dem gelben “Neu\" Tag. Nachdem alle verfügbaren Anteile am Primärmarkt verkauft wurden, können diese zwischen den Investor:innen am sog. Sekundärmarkt gehandelt werden. "])},
    "procedure_purchase_phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablauf der Finanzierungsphase"])},
    "profit_from_shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profitier von den Anteilen"])},
    "purchase_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bisherige Einnahmen"])},
    "serialNumbers_noSerial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier erscheint die Liste aller Seriennummern"])},
    "serialNumbersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seriennummern"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltene Anteile"])},
    "shares_secured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile gesichert"])},
    "shars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])},
    "someHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heute*"])},
    "timeline_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier."])},
    "timeline_note_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Die Dauer der Finanzierungsphase kann unter Umständen verlängert werden. Mehr Details "])},
    "timeline_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile von neuen Objekten werden zu einem Fixpreis angeboten."])},
    "timeline_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Finanzierungsphase wird am "])},
    "timeline_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abgeschlossen, kann aber unter Umständen verlängert werden."])},
    "timeline_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte laufende Einnahmen und handle die Anteile am Marktplatz."])},
    "timeline_text_4_firstPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte laufende Einnahmen"])},
    "timeline_text_4_secondPart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and trade your shares on the marketplace."])},
    "total_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Marktwert"])},
    "total_value_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtinvestment"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "value_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertänderung"])}
  },
  "Navigation": {
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "goForward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "NetworkError": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Verbindung!"])},
    "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfe die Internetverbindung."])},
    "toastText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfe die Internetverbindung"])}
  },
  "NewSecurityApp": {
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY APP VERKNÜPFEN"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App herunterladen"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Transaktionen signieren zu können, muss der Account mit einer Security App verknüpft werden."])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Security App erstellen"])},
    "underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Dokumentation ansehen"])}
  },
  "NoInvestments": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden zukünftige Investments gelistet."])},
    "screen_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskonto aufladen oder alternative Zahlungsmöglichkeit auswählen:"])}
  },
  "NotaryAppointment": {
    "austrianId_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verwende bereits ID Austira?"])},
    "austrianId_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "austrianId_noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beantragen Sie vor dem Notartermin eine ID Austria. Diese kann bei der Gemeinde, dem Magistrat, der Bezirkshauptmannschaft (BH) oder dem Finanzamt beantragt werden."])},
    "austrianId_noDescriptionMoreInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Informationen zur ID Austria finden Sie "])},
    "austrianId_noDescriptionMoreInformationLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "austrianId_noDescriptionRegistrationAuthorities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsbehören in der Nähe finden Sie "])},
    "austrianId_noDescriptionRegistrationAuthoritiesLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "austrianId_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "austrianId_yesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie vorab die Gültigkeit Ihrer ID Austria. Dies können Sie z.B. hier tun: "])},
    "austrianId_yesDescriptionLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatur Überprüfung"])},
    "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage schicken"])},
    "dropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin auswählen"])},
    "infoIconLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Termin wird nun an das Notariat weitergeleitet, das sich innerhalb der nächsten 48 Stunden mit weiteren Anweisungen melden wird."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle einen der folgenden Notartermine aus"])}
  },
  "NotFound": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Marktplatz"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum text:  Brickwise Investment GmbH wird meinen Namen und meine E-Mail-Adresse verarbeiten, um mir Marketing-Nachrichten per E-Mail zu eigenen oder ähnlichen Produkten zuzusenden."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite existiert zurzeit nicht"])}
  },
  "NotHappyWithTheAppModal": {
    "buttonCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "buttonOK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was können wir besser machen?"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback...."])}
  },
  "Notifications": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen aktivieren"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen aktivieren"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überspringen"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte hilfreiche Erinnerungen und Nachrichten."])}
  },
  "ObjectDetailsOverview": {
    "equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstattung"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockwerk"])},
    "heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizung"])},
    "market_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "noFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdgeschoss"])},
    "price_per_sqm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro m²"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räume"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struktur"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])}
  },
  "OpenOffersOverview": {
    "nothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine offenen Angebote vorhanden."])}
  },
  "Overview": {
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistung"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerstehend"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ab"])},
    "market_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "next_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung am (Tag)"])},
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich zum"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet ab"])},
    "rental_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erw. laufende Ausschüttung"])},
    "rentalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermietungsstatus"])},
    "share_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Anteile"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fläche"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermietungsstatus"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])}
  },
  "PayInModal": {
    "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
    "bic_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC in Zwischenablage kopiert"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönlicher IBAN"])},
    "iban_is_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN in Zwischenablage kopiert"])},
    "info_1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verwendungszweck kann frei gewählt werden, da dies ein persönliches Zahlungskonto ist. Es erfolgt eine Benachrichtigung, sobald das Geld verfügbar ist."])},
    "info_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisungen kommen normalerweise innerhalb von zwei Banktagen an."])},
    "info_2_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käufe können jederzeit auch direkt bezahlt werden."])},
    "info_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahle direkt per Kreditkarte, ApplePay oder Sofortüberweisung."])},
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachte folgendes"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskonto aufladen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlen"])}
  },
  "PayInView": {
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN wurde in die Zwischenablage kopiert!"])}
  },
  "PaymentRedirect": {
    "midText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließe das Browserfenster um die Transaktion abzuschließen"])}
  },
  "PayOutModal": {
    "amount_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlen"])},
    "amount_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlen"])},
    "amount_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
    "amount_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verfügbar"])},
    "code_confirmation_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der eingebene Code ist leider falsch"])},
    "code_confirmation_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätige den Code"])},
    "code_confirmation_sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben eine SMS an"])},
    "code_confirmation_sms_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geschickt."])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungskonto"])},
    "max_amount_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Geld verfügbar"])},
    "max_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganzen Betrag auszahlen"])},
    "min_amount_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mindestauszahlungsbetrag ist € 0,10."])},
    "transaction_confirmation_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "transaction_confirmation_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung bestätigt"])}
  },
  "PersonalDocuments": {
    "brickwise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Investment GmbH"])},
    "cost_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosteninformation"])},
    "customer_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeninformationen"])},
    "effecta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effecta GmbH"])},
    "general_terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Geschäftsbedingungen"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Dokumente"])},
    "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay SA"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
    "service_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicevertrag"])},
    "terms_of_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])}
  },
  "PowerPlantBarChart": {
    "averagePerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ø Ertrag pro Tag"])},
    "todayProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ertrag heute"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])}
  },
  "PowerPlantInfo": {
    "constructionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "constructionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "lifespan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplante Nutzungsdauer"])},
    "marketValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marktkapitalisierung"])},
    "moduleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Module"])},
    "nextPayout_firstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich zum"])},
    "nextPayout_secondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartet ab"])},
    "nominalPowerPerModule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominal-Nennleistung kWp pro Modul"])},
    "powerOutput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistung"])},
    "powerOutputPerAnno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voraus. Erzeugung pro Jahr"])},
    "powerPlantType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])},
    "propertySecurity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsicherung"])},
    "rentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Ausschüttung"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicher"])},
    "storageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicher Kapazität"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraftwerk Infos"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])}
  },
  "PrivateSecurityApp": {
    "brickwise_security_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brickwise Security App verknüpfen"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfen"])},
    "connected_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Kaufangebot fortfahren"])},
    "connected_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbunden."])},
    "connected_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY APP"])},
    "connected_private_key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key"])},
    "connected_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast die eine private Security App am"])},
    "connect_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt verknüpfen"])},
    "failed_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key überprüfen"])},
    "failed_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung fehlgeschlagen"])},
    "failed_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Wie in der Dokumentation beschrieben, muss dein Wallet über das bereitgestellte Signations Script verfügen."])},
    "failed_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Verknüpfung deiner privaten Security App ist leider etwas schiefgelaufen. Bitte überprüfe die angegebenen Daten und versuche es erneut."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Security App verknüpfen"])},
    "popup_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung!"])},
    "popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit deiner privaten Security App, kannst du nur private Angebote auf unserem Marktplatz platzieren und annehmen. Es werden für dich daher nicht alle Angebote ersichtlich sein."])},
    "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge hier den Public Key ein, um die Security App zu verknüpfen. Unsere "])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Dokumentation"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["haben wir hier bereitgestellt."])}
  },
  "ReadMore": {
    "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])}
  },
  "RealEstateInfo": {
    "buildingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bautyp"])},
    "doorNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Nr."])},
    "energy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENERGIEBEDARF"])},
    "equipment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstattung"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stockwerk"])},
    "heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizung"])},
    "noFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdgeschoss"])},
    "photos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRUNDRISS"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Räume"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Größe"])},
    "structure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struktur"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien Infos"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])}
  },
  "RecordsView": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier werden alle aktuellen sowie vergangene Informationen und Dokumente zum Objekt angezeigt."])},
    "empty_records": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt bisher keine Updates zu diesem Objekt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updates zum Objekt"])}
  },
  "ReferralModal": {
    "acceptedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladung erfolgreich angenommen!"])},
    "btn_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund:innen einladen"])},
    "btn_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los geht’s"])},
    "bullet1_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versende den Einladungslink"])},
    "bullet1_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile die große Freude"])},
    "bullet2_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemand eröffnet einen Account"])},
    "bullet2_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Und schließt das erste Investment ab"])},
    "bullet3_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr beide freut euch über"])},
    "bullet3_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geteilte Freude ist doppelte Freude"])},
    "bullet4_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eröffne einen Account "])},
    "bullet4_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließe die Verifizierung vollständig ab"])},
    "bullet5_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investiere in eine Immobilie "])},
    "bullet5_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finde das passende Investment für dich"])},
    "bullet6_bold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte einen "])},
    "bullet6_bold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus"])},
    "bullet6_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bonus wird direkt gutgeschrieben"])},
    "inviteTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund:innen einladen und"])},
    "inviteTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kassieren."])},
    "referralLinkCopiedWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungslink kopiert"])},
    "referralShare_dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Freunde einladen!"])},
    "share_dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Freunde einladen!"])},
    "share_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach mit diesem Link die"])},
    "share_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-App herunterladen und"])},
    "share_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["als Bonus auf das erste Investment sichern."])},
    "share_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus für das erste Investment?"])},
    "terms_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Versenden des Links, stimme ich den"])},
    "terms_text_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmebedingungen"])},
    "terms_text_zu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zu."])}
  },
  "ReminderDialog": {
    "button1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morgen Abend"])},
    "button2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag Nachmittag"])},
    "button3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später erinnern"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Accounterstellung kann auch später fortgesetzt werden."])}
  },
  "RemoveSecurityApp": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App trennen"])},
    "checkbox_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe meine Wiederherstellungsdatei"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um eine neue Security App mit deinem Account zu verknüpfen, musst du vorher die bestehende trennen."])},
    "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY APP ENTFERNEN"])},
    "old_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App trennen"])},
    "old_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um eine neue Security App mit deinem Account zu verknüpfen, musst du vorher die bestehende trennen."])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECURITY APP TRENNEN"])},
    "unable_to_disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Fehler aufgetreten. Bitte schreib uns unter support", "@", "brickwise.at damit wir uns um dein Anliegen kümmern können."])},
    "underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellungsdatei nicht verfügbar?"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um eine neue Security App zu verknüpfen, wird die Wiederherstellungsdatei benötigt, welche im Rahmen des Security-App Setups abgespeichert wurde."])}
  },
  "RentDetails": {
    "administrative_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltungskosten"])},
    "currentDistributionYield_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Ausschüttungsrendite"])},
    "dissolutionLiquidityReserveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auflösung Liquiditätsrücklage /\nVorstreckung Emittentin"])},
    "distribution_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttung"])},
    "distribution_yield": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell erw. Ausschüttungsrendite"])},
    "distributionYield_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungspotenzial"])},
    "distributionYield_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungspotenzial"])},
    "document_rentalAgreement_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietvertrag"])},
    "interestFees_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen und Gebühren"])},
    "liquidity_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquididätsrücklage"])},
    "management_cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managementkosten"])},
    "net_rental_income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-Mieteinnahmen"])},
    "normalScenario_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittleres Szenario"])},
    "payOutPotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttungspotenzial"])},
    "perAnnual_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["p.a."])},
    "performance_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertentwicklungspotenzial"])},
    "performancePotential_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertentwicklungspotenzial"])},
    "performance_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertentwicklung"])},
    "periodUnderConsideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrachtungszeitraum"])},
    "powerplant_rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Einnahmen"])},
    "rental_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietübersicht"])},
    "rentInfo_returnOnEquity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietübersicht und Kalkulation"])},
    "rentSurplusAfterInterest_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-Mietüberschuss (nach Zinsen)"])},
    "rentSurplusBeforeInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-Mietüberschuss (vor Zinsen)"])},
    "rentSurplus_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netto-Mietüberschuss"])},
    "repayment_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgung"])},
    "returnOnEquity_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "returnOnEquityModal_legalParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die vermittelten Finanzprodukte sind mit erheblichen Risiken verbunden und können zum vollständigen Verlust des eingesetzten Kapitals führen. Produkte mit einem Fremdkapitalanteil können sich aufgrund der Hebelwirkung (Leverage) überproportional sowohl positiv als auch negativ entwickeln. Wir weisen ausdrücklich darauf hin, dass vergangene Entwicklungen und Prognosen kein zuverlässiger Indikator für künftige Wertentwicklungen sind und es sich bei den Renditeangaben lediglich um kalkulierte Werte handelt."])},
    "returnOnEquityModal_scenarioPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial Szenarien"])},
    "returnOnEquityModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditepotenzial"])},
    "until_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "yearSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J"])},
    "yearsSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])}
  },
  "RentInfoFooter": {
    "object_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOKUMENTE"])}
  },
  "RentPeriod": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die nachfolgende Quote gibt Auskunft über den durchschnittlichen Zeitraum pro Jahr, in dem die Immobilie vermietet war. "])},
    "progress_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Informationen lassen keine Rückschlüsse auf zukünftige Entwicklungen zu."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermietungsgrad"])}
  },
  "SecurityAppIntegrationIdentification": {
    "privateSecurityApp_modal_default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Dokumentation ansehen"])},
    "privateSecurityApp_modal_first_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kann eine eigene Security App mit dem Account verknüpft werden. Informationen zur Erstellung einer eigenen Security App haben wir in der"])},
    "privateSecurityApp_modal_first_paragraph_second_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zusammengefasst."])},
    "privateSecurityApp_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Security App erstellen"])},
    "privateSecurityApp_modal_main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Security App verbinden"])},
    "privateSecurityApp_modal_second_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Mit einer privaten Security App können nur noch private Angebote erstellt bzw. angenommen werden."])},
    "privateSecurityApp_modal_underlined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Dokumentation"])},
    "securityAppIntegration_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App verbinden"])},
    "securityAppIntegration_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade die empfohlene Security App herunter oder erstelle eine eigene."])},
    "securityAppIntegration_modal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Security App erstellen"])},
    "securityAppIntegration_next_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App herunterladen"])}
  },
  "SecurityAppOverlay": {
    "api_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
    "back_to_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Marktplatz"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebots ID Kopiert"])},
    "offer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebots ID"])},
    "open_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die"])},
    "open_text1_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der folgenden Angebots ID kannst du die Transaktion in deiner privaten"])},
    "open_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App,"])},
    "open_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um das Angebot zu signieren."])},
    "open_text3_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signieren."])},
    "open_text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um den Kauf zu signieren."])},
    "own_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben"])},
    "own_app1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die API Dokumentation zur Erstellung einer eigenen Security App veröffentlicht."])},
    "own_app1_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um die Abwicklung der Transaktionen schnell und unkompliziert durchzuführen."])},
    "own_app_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfe die"])},
    "secApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])}
  },
  "SecurityAppSettings": {
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbunden"])},
    "connected_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Security App wurde am"])},
    "header_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Security App verknüpfen"])},
    "popup_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App herunterladen"])},
    "popup_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App verbinden"])},
    "popup_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade die empfohlene Security App herunter oder erstelle eine eigene."])},
    "popup_underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Security App erstellen"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Key"])}
  },
  "SecurityDetails": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förderung & Vorteile"])}
  },
  "SelectPartner": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie Ihren bevorzugten Anbieter"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter auswählen"])}
  },
  "Settings": {
    "delete_account_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist bedauerlich, dass wir mit unserer App nicht überzeugen konnten. Über Feedback oder eine zweite Change würden wir uns freuen. Durch Drücken des Buttons wird der Account samt Daten innerhalb von 4-7 Tagen gelöscht. Anschließend wird eine Bestätigung per E-Mail gesendet."])},
    "delete_account_modal_button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete_account_modal_button_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account löschen"])},
    "delete_account_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account und Daten löschen"])},
    "experience_button_skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angaben machen"])},
    "experience_button_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "experience_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemessenheit"])},
    "experience_text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind gesetzlich dazu verpflichtet, genaue und aktuelle Informationen über die Kenntnisse und Erfahrungen unserer Kunden mit verschiedenen Finanzinstrumenten und Wertpapierdienstleistungen einzuholen."])},
    "experience_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittels dieser Angaben können wir prüfen, ob die erforderlichen Kenntnisse und Erfahrungen bestehen, um die Risiken im Zusammenhang mit der Art der Finanzinstrumente oder Wertpapierdienstleistungen angemessen beurteilen zu können."])},
    "experience_text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angaben sind freiwillig. Sollten jedoch keine oder unvollständige Angaben gemacht werden, können wir die Angemessenheit nicht prüfen."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])}
  },
  "SettingsList": {
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zur Betreiberin"])},
    "address_details_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnadresse"])},
    "address_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollten sich Daten geändert haben, können diese dem Support übermittelt werden. "])},
    "announcements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitteilungen"])},
    "app_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Einstellungen"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "change_passowd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatsbürgerschaft"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "connected_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich verbunden"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere uns"])},
    "delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account löschen"])},
    "delete_account_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account schließen"])},
    "direct_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte direkte Unterstützung"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail Adresse"])},
    "face_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face ID"])},
    "finger_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch ID"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer"])},
    "how_can_we_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie können wir helfen?"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungskonto"])},
    "important_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle wichtigen Unterlagen"])},
    "important_reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Erinnerungen erhalten"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "invite_a_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freund:innen einladen"])},
    "knowledge_experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse & Erfahrungen"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "marketing_newsletter_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten erhalten"])},
    "marketing_newsletter_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten erhalten"])},
    "my_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Dokumente"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "not_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verbunden"])},
    "payout_details_legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte sich das Auszahlungskonto ändern, übermittle die neue IBAN inkl. eines Nachweises (z.B. Bild der Bankkarte, Kontoauszug) an den Support."])},
    "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
    "questions_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen und Antworten"])},
    "realpartIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Auszahlung"])},
    "residential_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnadresse"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])},
    "security_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "share_invite_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einladungslink teilen"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "taxID_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füge eine Steuer-ID hinzu"])},
    "taxID_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-ID"])},
    "update_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben aktualisieren"])},
    "use_face_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face-ID zum Entsperren nutzen"])},
    "use_finger_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch ID zum Entsperren nutzen"])}
  },
  "ShareBadge": {
    "message_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe eine interessante Investitionsmöglichkeit gefunden!"])}
  },
  "SignatureDocuments": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor es los geht, musst du noch unsere Vereinbarungen akzeptieren und signieren."])},
    "titleBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abschließen"])},
    "titleLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung"])}
  },
  "SupportCallBackModal": {
    "calendar_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call mit"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückruf"])},
    "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir rufen zurück:"])},
    "phone_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "select_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster auswählen"])},
    "select_weekday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochentag auswählen"])},
    "send_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "success_modal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "success_modal_button_calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kalender hinzufügen"])},
    "success_modal_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückruf vereinbart"])},
    "success_modal_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir werden am ", _interpolate(_named("date")), " um ", _interpolate(_named("time")), " unter ", _interpolate(_named("phone")), " zurückrufen."])},
    "success_modal_text_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir melden uns in Kürze!"])},
    "textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie können wir helfen?"])}
  },
  "SupportView": {
    "account_opening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Eröffnung"])},
    "account_opening_realpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoeröffnung"])},
    "builder_owner_modell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bauherrenmodell"])},
    "call_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückruf vereinbaren"])},
    "contact_us_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere uns"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "investments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investments"])},
    "investments_realpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionen"])},
    "payment_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungskonto"])},
    "personal_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien"])},
    "q_and_a_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen und Antworten"])},
    "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche..."])},
    "search_dialog_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen und Antworten"])},
    "search_dialog_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben keine passende Antwort gefunden!"])},
    "search_dialog_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheit"])},
    "security_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App"])},
    "send_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
    "tax_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuern"])},
    "trading_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsplatz"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung"])},
    "verification_realpart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierung"])}
  },
  "SupportWriteUsModal": {
    "email_error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegeben E-Mail-Adresse is ungültig"])},
    "email_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht"])},
    "info_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Antwort schicken wir an:"])},
    "send_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "success_modal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "success_modal_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht gesendet"])},
    "success_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir melden uns in Kürze!"])},
    "textarea_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie können wir helfen?"])}
  },
  "TaxIdDetails": {
    "churchMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied der Kirche?"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültig"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollten sich Daten geändert haben, können diese dem Support übermittelt werden. "])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-ID"])},
    "mainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füge hier eine Steuer-ID hinzu."])},
    "successToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-ID wurde hinzugefügt"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-ID"])}
  },
  "TaxIdModal": {
    "default_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In ein anderes Produkt investieren"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültig"])},
    "inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
    "main_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer hinzufügen"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die laufenden Erträge dieses Produkts unterliegen der Einkommenssteuer in Österreich. Um in dieses Produkt investieren zu können, wird eine österreichische Steuernummer benötigt."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionen in Produkte, deren Einkünfte der Kapitalertragssteuer (KESt/KapEst) unterliegen, sind hingegen jederzeit möglich. Diese sind am KESt-Tag am Marktplatz erkenntlich."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei diesem Produkt wird eine österreichische Steuernummer benötigt"])}
  },
  "TradingButton": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "buy_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Anteile kaufen"])},
    "reservationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vormerken"])},
    "reservationModal_buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vormerken"])},
    "reservationModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserviere einen Betrag an dem Asset vor. Erhalte eine Email sobald das Asset zum Verkauf steht."])},
    "reservationModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorreservierung"])},
    "reservationModal_inputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierungsbetrag in €"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufen"])},
    "userPendingModal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir informieren per E-Mail, sobald wir alle Informationen überprüft haben."])},
    "userVerifyModal_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verifizieren"])},
    "userVerifyModal_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließe die Verifizierung ab, um zu investieren."])},
    "userVerifyModal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account ist noch nicht vollständig verifiziert!"])}
  },
  "TradingOfferOverview": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "delete_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot bearbeiten?"])},
    "delete_offer_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot löschen"])},
    "private_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Kontaktaufnahme"])},
    "private_contact_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimm Kontakt mit der Käufer:in auf, um Details zur Transaktion und Zahlungsabwicklung zu vereinbaren:"])},
    "private_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Verkaufsabschluss wird eine Vermittlungsgebühr in der Höhe von"])},
    "private_info1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit dem persönlichen Zahlungskonto verrechnet."])},
    "private_release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf freigeben"])},
    "private_release_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald die Zahlung durchgeführt wurde, kann der Verkauf der Anteile freigeben werden."])},
    "private_release_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verkauf wurde freigegeben."])},
    "private_release_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf freigegeben"])},
    "private_sale_other_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe der Käufer:in"])},
    "private_sale_other_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald auch die Käufer:in die Transaktion freigegeben hat, werden die Anteile übertragen."])},
    "private_sale_other_party_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Käufer:in hat die Transaktion freigegeben. Die Anteile wurden übertragen."])},
    "private_secondparty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme der Anfrage durch Verkäufer:in"])},
    "private_secondparty_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte auf die Freigabe der Verläufer:in. Danach werden die gewünschten Anteile übertragen."])},
    "process_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktion hat erfolgreich stattgefunden, der Verkaufserlös wurde dem Zahlungskonto gutgeschrieben."])},
    "sale_accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annahme des Angebots durch eine:n Käufer:in"])},
    "sale_accepted1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Käufer:in hat Interesse an dem Angebot"])},
    "sale_accepted1_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließe den Verkauf ab."])},
    "sale_accepted_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgt eine Benachrichtigung, sobald ein:e Käufer:in an dem Verkaufsangebot Interesse zeigt."])},
    "sale_accepted_second_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung durch Käufer:in"])},
    "sale_accepted_second_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgt eine Benachrichtigung, sobald die Käufer:in die Transaktion signiert hat. Der Gesamterlös wird anschließend dem Zahlungskonto gutgeschrieben."])},
    "sale_finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf abschließen"])},
    "sale_finish_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App um den Verkauf abzuschließen. Der Gesamterlös wird anschließend dem Zahlungskonto gutgeschrieben."])},
    "sale_finish_second_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf abgeschlossen"])},
    "sale_finish_second_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Käufer:in hat die Transaktion signiert. Der Gesamterlös wurde dem Zahlungskonto gutgeschrieben."])},
    "sale_released": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsangebot signieren"])},
    "sale_released_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App um das Verkaufsangebot zu signieren."])},
    "sale_released_second_party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf signieren"])},
    "sale_released_second_party_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffne die Security App, um den Verkauf zu signieren."])},
    "sale_released_second_party_body_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verkauf wurde mit der Security App signiert."])},
    "sale_released_second_party_done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf signiert"])},
    "sale_release_private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf freigeben"])},
    "sale_release_private_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald die Zahlung durchgeführt wurde, kann der Verkauf der Anteile freigeben werden."])},
    "sale_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsangebot signiert"])},
    "sale_signed_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Verkaufsangebot wurde mit der Security App signiert."])},
    "selling_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufangebot"])}
  },
  "TradingOverviewBox": {
    "purchase_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
    "sale_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufspreis"])},
    "share_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "shares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteile"])}
  },
  "TradingStatus": {
    "iban_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du benötigst keinen Verwendungszweck. Es kann einige Tage dauern bis deine Zahlung in userem System eingelangt ist."])}
  },
  "TransactionCard": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
    "buy_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf abgebrochen"])},
    "notaryMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten auf Notardokumente"])},
    "paymentMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung ausständig"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])}
  },
  "TransactionOverlay": {
    "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gutschrift"])},
    "buy_canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABGEBROCHENER KAUF"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermittlungsgebühr"])},
    "fiscalPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum der Erträge"])},
    "footnote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Die Steuer in der Höhe von "])},
    "footnote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wird von der Emittentin automatisch abgeführt. "])},
    "investment_t_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionsbedingungen"])},
    "ISIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt (ISIN)"])},
    "kest_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Transaktion wurde rückwirkend am 15.02.2022 korrigiert und um die ursprünglich abgezogene Kapitalertragssteuer erhöht. Die einbehaltene Kapitalertragssteuer wurde am 15.02.2022 im Rahmen dieser Korrektur auf deinem Konto gutgeschrieben. "])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsart"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlung von Zahlungskonto"])},
    "payout_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszahlungsbetrag"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausführungsplatz"])},
    "price_per_share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis pro Anteil"])},
    "purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GEKAUFTE ANTEILE"])},
    "purchase_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufpreis"])},
    "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EINNAHME"])},
    "rental_brutto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausschüttung"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERKAUFTE ANTEILE"])},
    "sale_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufpreis"])},
    "share_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Anteile"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "status_canceledOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
    "status_inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
    "status_notaryMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warten auf Notardokumente"])},
    "status_paymentMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung ausständig"])},
    "status_pending_bankWire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warte auf Einzahlung"])},
    "status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
    "taxOfficeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzamt, an das die Steuern abgeführt werden"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbetrag"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertpapierart"])}
  },
  "UpdateApp": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit für ein Update!"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update installieren"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bitten um die Installation der aktuellsten Version unserer App."])}
  },
  "UpdatePassword": {
    "face_id_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Face ID für die Anmeldung zu aktivieren, ist das aktuelle Passwort erforderlich."])},
    "face_id_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face ID aktivieren"])},
    "finger_id_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Touch ID für die Anmeldung zu aktivieren, ist das aktuelle Passwort erforderlich."])},
    "finger_id_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touch ID aktivieren"])},
    "new_password_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort aktualisieren "])},
    "new_password_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "new_password_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ein neues Passwort. Bitte beachte die Richtlinen für ein sicheres Passwort."])},
    "new_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "new_password_validation_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 10 Zeichen und ein Großbuchstabe"])},
    "old_password_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "old_password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist nicht korrekt"])},
    "old_password_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
    "old_password_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der Eingabe des aktuellen Passworts kann im darauffolgenden Schritt ein neues festgelegt werden."])},
    "old_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
    "password_change_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Ändern des Passworts."])},
    "success_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter zur Anmeldung "])},
    "success_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung kann nun mit dem neuen Passwort erfolgen."])},
    "success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort geändert"])}
  },
  "UpdateSecurityApp": {
    "linkNewSecurityApp_popupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Geräte verknüpfen?"])},
    "linkNewSecurityApp_popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst immer nur mit einer Security App gleichzeitig verknüpft sein. Wenn du die Verbindung zu deiner aktuellen Security App trennst, ist diese danach nicht mehr verfügbar."])},
    "newSecurityApp_popupButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Security App verknüpfen"])},
    "newSecurityApp_popupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle deine private Security App!"])},
    "newSecurityApp_popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du deine eigene Security App mit deinem Brickwise Account verknüpfen."])},
    "newSecurityApp_popupText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls du noch keine eigene Security App  erstellt hast, sieh dir die API Dokumentation an, um dir diese zu programmieren."])},
    "newSecurityApp_popupText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung! Mit deiner privaten Security App, kannst du nur noch private Angebote erstellen und annehmen."])},
    "newSecurityApp_popupUnderlineButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Dokumentation ansehen"])},
    "removeSecurityApp_popupButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail an den Support senden"])},
    "removeSecurityApp_popupHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellungsdatei nicht verfügbar?"])},
    "removeSecurityApp_popupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte kontrolliere erneut, ob deine Wiederherstellungsdatei nicht doch auffindbar ist. Solltest du auch keinen Zugriff mehr auf dein altes Gerät haben, um dir dort die Wiederherstellungsdatei nochmal herunter zu laden, kann diese zu deiner Sicherheit nur mit Hilfe unseres Supports  zurückgesetzt werden."])}
  },
  "UserView": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])}
  },
  "VerificationOption": {
    "headerBold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifikation"])},
    "headerLight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren mit"])},
    "mobileOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortfahren in der App"])},
    "mobileVerificationModal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der QR Code muss mit dem Handy eingescannt werden"])},
    "mobileVerificationModal_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ihre Identität zu überprüfen, müssen Sie sich mit einem gültigen Dokument ausweisen.  Vorzugsweise auf einem mobilen Endgerät."])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ihre Identität zu überprüfen, müssen Sie sich mit einem gültigen Dokument ausweisen.  Vorzugsweise auf einem mobilen Endgerät."])},
    "recommendedOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir empfehlen die Nutzung der mobilen Version."])},
    "webOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Webbrowser fortfahren"])}
  },
  "VerificationStatus": {
    "failedMainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "failedTertiaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "failedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, etwas ist schiefgelaufen!"])},
    "pendingMainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "pendingTertiaryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich brauche Hilfe"])},
    "pendingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry! Die Überprüfung deiner Daten dauert länger"])},
    "successButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später fortfahren"])},
    "successParagraph_comeBackLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen kurzen Augenblick..."])},
    "successParagraph_redirecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir bitten um einen kurzen Moment..."])},
    "successTitle_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["überprüfen"])},
    "successTitle_light1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir"])},
    "successTitle_light2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gerade die Informationen"])}
  },
  "WebMenu": {
    "imprintLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "loggedInAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeloggt als:"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
    "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "privacyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account erstellen"])},
    "registrationList_createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account eröffnen"])},
    "registrationList_InvestAndBenefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investieren und profitieren"])},
    "registrationList_KnowledgeAndExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenntnisse und Erfahrungen"])},
    "registrationList_personalData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Daten"])},
    "registrationList_SecurityApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security App verknüpfen"])},
    "registrationList_VerifyIdentity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifizierung"])}
  },
  "YieldCalculator": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renditerechner*"])},
    "offline_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Renditerechner kann zurzeit nicht angezeigt werden."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Die angezeigten Werte sind kein Indikator für zukünftige Erträge oder Wertentwicklungen. Jede Investition ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen."])}
  },
  "ZipLoadingIndicator": {
    "zipLoadingIndicator_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App bitte nicht schließen"])},
    "zipLoadingIndicator_light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das hochladen der Daten kann je nach Internetverbindung bis zu einer Minute dauern. Bitte bleibe solange auf dieser Seite."])}
  },
  "texterify_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2024-05-31T07:55:12Z"])}
}