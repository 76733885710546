import { RouteRecordRaw } from 'vue-router';

const IntroSlides = () => import('@/views/InitialScreens/IntroSlides.vue')
const SelectPartner = () => import('@/views/InitialScreens/SelectPartner.vue')
const Login = () => import('@/views/NativeLogin.vue')
const Register = () => import('@/views/Identification/NativeRegister.vue')
const CompanyRegistration = () => import('@/views/Identification/NativeCompanyRegistration.vue')
const PersonalInfo = () => import('@/views/Identification/PersonalInfo.vue')
const Identification = () => import('@/views/Native/Identification/Identification.vue')
const ExternalIdentification = () => import('@/views/Identification/ExternalIdentification.vue')
const ExternalIdentificationFailed = () => import('@/views/Identification/ExternalIdentificationFailed.vue')
const KnowledgeAndExperience = () => import('@/views/Identification/KnowledgeAndExperience.vue')
const SecurityAppIntegration = () => import('@/views/Identification/SecurityAppIntegration.vue')
const ElectronicSignature = () => import('@/views/Identification/ElectronicSignature.vue')
const BankAccountVerification = () => import('@/views/Identification/BankAccountVerification.vue')
const ResetPassword = () => import('@/views/ResetPassword.vue')
const Settings = () => import('@/views/NativeSettings.vue')
const UpdateSecurityApp = () => import('@/views/SecurityApp/UpdateSecurityApp.vue')
const PrivateSecurityApp = () => import('@/views/SecurityApp/PrivateSecurityApp.vue')
const MarketPlace = () => import('@/views/MainTabs/NativeMarketplace.vue')
const Investments = () => import('@/views/MainTabs/NativeInvestments.vue')
const Account = () => import('@/views/MainTabs/NativeAccount.vue')
const Support = () => import('@/views/MainTabs/NativeSupport.vue')
const Inbox = () => import('@/views/MainTabs/NativeInbox.vue')
const InboxDetail = () => import('@/views/Inbox/InboxDetail.vue')
const InvestmentView = () => import('@/views/Asset/InvestmentView.vue')
const PowerPlantPortfolioInvestment = () => import('@/views/Native/Trading/PowerPlantPortfolioInvestment.vue')
const PartnerInvestment = () => import('@/views/Native/Trading/PartnerInvestment.vue')
const BuilderOwnerModelInvestment = () => import('@/views/Native/Trading/BuilderOwnerModelInvestment.vue')
const TradingView = () => import('@/views/Asset/TradingView.vue')
const BuyOfferOverview = () => import('@/views/Offers/BuyOfferOverview.vue')
const TradingOfferOverview = () => import('@/views/Offers/TradingOfferOverview.vue')
const ImprintInfo = () => import('@/views/Asset/ImprintInfo.vue')
const UpdateApp = () => import('@/views/Utils/UpdateApp.vue')
const Biometrics = () => import('@/views/Utils/NativeBiometrics.vue')
const Notifications = () => import('@/views/Utils/NativeNotifications.vue')
const BiometricLogin = () => import('@/views/BiometricLogin.vue')
const AddPayout = () => import('@/views/AddPayout.vue')
const SupportWriteUs = () => import('@/views/Support/SupportWriteUs.vue')
const SupportCallBack = () => import('@/views/Support/SupportCallBack.vue')
//Native - Asset
const NativeAsset = () => import('@/views/Asset/v2/NativeAsset.vue')

const appRoutes: Array<RouteRecordRaw> = [
  {
    name: 'AppMarketPlace',
    path: '/app/marketplace',
    component: MarketPlace,
  },
  {
    name: 'AppInvestments',
    path: '/app/investments',
    component: Investments,
  },
  {
    name: 'AppAccount',
    path: '/app/account',
    component: Account,
    props: true,
  },
  {
    name: 'AppInbox',
    path: '/app/inbox',
    component: Inbox,
  },
  {
    name: 'AppInboxDetail',
    path: '/app/inbox/:id',
    component: InboxDetail,
    props: true,
  },
  {
    name: 'AppSupport',
    path: '/app/support',
    component: Support,
  },
  {
    name: 'AppLogin',
    path: '/app/login',
    component: Login,
  },
  {
    name: 'AppBiometricLogin',
    path: '/app/biometricLogin',
    component: BiometricLogin,
  },
  {
    name: 'AppIntroSlides',
    path: '/app/introSlides',
    component: IntroSlides,
  },
  {
    name: 'AppSelectPartner',
    path: '/app/selectPartner',
    component: SelectPartner,
  },
  {
    name: 'AppRegister',
    path: '/app/register',
    component: Register,
  },
  {
    name: 'AppCompanyRegistration',
    path: '/app/company-registration',
    component: CompanyRegistration,
  },
  {
    name: 'AppPersonalInfo',
    path: '/app/personal-info',
    component: PersonalInfo,
  },
  {
    name: 'AppIdentification',
    path: '/app/identification',
    component: Identification,
  },
  {
    name: 'AppExternalIdentification',
    path: '/app/external-identification',
    component: ExternalIdentification,
  },
  {
    name: 'AppExternalIdentificationFailed',
    path: '/app/external-identification-failed',
    component: ExternalIdentificationFailed,
  },
  {
    name: 'AppKnowledgeAndExperience',
    path: '/app/knowledge-and-experience',
    component: KnowledgeAndExperience,
  },
  {
    name: 'AppSecurityAppIntegration',
    path: '/app/security-app-integration',
    component: SecurityAppIntegration,
  },
  {
    name: 'AppElectronicSignature',
    path: '/app/electronic-signature',
    component: ElectronicSignature,
  },
  {
    name: 'AppBankAccountVerification',
    path: '/app/bank-account-verification',
    component: BankAccountVerification,
  },
  {
    name: 'AppResetPassword',
    path: '/app/reset-password',
    component: ResetPassword,
  },
  {
    name: 'AppSettings',
    path: '/app/settings',
    component: Settings,
    props: true
  },
  {
    name: 'AppUpdateSecurityApp',
    path: '/app/update-security-app',
    component: UpdateSecurityApp,
  },
  {
    name: 'AppPrivateSecurityApp',
    path: '/app/private-security-app',
    component: PrivateSecurityApp,
  },
  {
    name: 'AppSupportWriteUs',
    path: '/app/support/writeUs',
    component: SupportWriteUs,
  },
  {
    name: 'AppSupportCallBack',
    path: '/app/support/callBack',
    component: SupportCallBack,
  },
  {
    name: 'AppNativeAsset',
    path: '/app/asset/:id',
    component: NativeAsset,
  },
  {
    name: 'AppInvestment',
    path: '/app/investment/:id',
    component: InvestmentView,
    props: true,
  },
  {
    name: 'AppNativePowerPlantPortfolioInvestment',
    path: '/app/powerplant-portfolio-investment/:id',
    component: PowerPlantPortfolioInvestment
  },
  {
    name: 'AppNativePartnerInvestment',
    path: '/app/partner-investment/:id',
    component: PartnerInvestment
  },
  {
    name: 'AppNativeBuilderOwnerModelInvestment',
    path: '/app/building-investment/:id',
    component: BuilderOwnerModelInvestment
  },
  {
    name: 'AppTrading',
    path: '/app/trading/:id',
    component: TradingView,
    props: true,
  },
  {
    name: 'AppBuyOfferOverview',
    path: '/app/buy-offer-overview/:id',
    component: BuyOfferOverview,
  },
  {
    name: 'AppTradingOfferOverview',
    path: '/app/trading-offer-overview/:id',
    component: TradingOfferOverview,
  },
  {
    name: 'AppImprintInfo',
    path: '/app/asset/:id/imprint-info',
    component: ImprintInfo,
  },
  {
    name: 'AppUpdateApp',
    path: '/app/updateApp',
    component: UpdateApp,
  },
  {
    name: 'AppBiometrics',
    path: '/app/biometrics',
    component: Biometrics,
  },
  {
    name: 'AppNotifications',
    path: '/app/notifications',
    component: Notifications,
  },
  {
    name: 'AppAddPayout',
    path: '/app/add-payout',
    component: AddPayout,
  },
]

export default appRoutes;
